<template>
    <div class="mb-1">
        <div class="row">
            <div class="col-5">
                <div class="input-group">
                    <div class="input-group-prepend"><a href="#" @click.prevent="deleteRow(line.row_position)"><i class="fal fa-ellipsis-v text-xl mr-2 mt-1"></i></a></div>
                    <input 
                        class="form-control form-control-sm" 
                        type="search" 
                        id="linedesc" 
                        name="linedesc" 
                        list="products" 
                        @change="selectedProduct"
                        v-model="line.line_desc">
                    <datalist id="products">
                        <option v-for="product in prodList">{{ product.text }}</option>
                    </datalist>                                    
                </div>
            </div>
            <div class="col-2">
                <input 
                	class="form-control form-control-sm text-right" 
            		type="text" 
            		name="qty" 
            		@input="doUpdateUnit"
                    :class="getQtyClass()"
            		v-model="line.qty">
            </div>
            <div class="col-2">
                <input class="form-control form-control-sm text-right" type="text" name="unit" v-model="line.unit">
            </div>
            <div class="col-3">
                <span class="form-control form-control-sm text-right" name="total" v-text="getFormattedDecimal(total)"></span>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-3 input-group" v-if="isSubscription()" style="position: relative; left: 9px;">
                <input class="form-control form-control-sm" type="text" name="subscription" placeholder="License Id" v-model="line.license_id">
                <button class="input-group-append border border-grey rounded border-grey-light text-xs text-grey-dark" @click.prevent="findLicense"><i class="fas fa-search mt-2"></i></button>
            </div>
            <div :class="(isSubscription()) ? 'col-8' : 'col-9'" style="position: relative; left: 9px;">
                <textarea class="form-control form-control-sm" name="details" placeholder="Detailed description" rows=1 v-model="line.line_details"></textarea>
            </div>
        </div>
        <div class="row mt-2" v-if="hasLicense">
            <div class="col-12 text-sm text-grey-dark" v-html="showLicense()"></div>
        </div>
    </div>
</template>

<script>

import _ from 'lodash'
var moment = require('moment');
	
export default {
    props: ['invoiceLine', 'prodList', 'index'],

    data() {
        return {
			line : {
                   invoice_id: this.invoiceLine.invoice_id,
                   row_position: this.index,
                   code: this.invoiceLine.code,
                   line_desc: this.invoiceLine.line_desc,
                   line_details: this.invoiceLine.line_details,
                   qty: this.invoiceLine.qty,
                   unit: parseFloat(this.invoiceLine.unit),
                   total: parseFloat(this.invoiceLine.total),
                   license_id: this.invoiceLine.license_id,
                   originalUnit: parseFloat(this.invoiceLine.unit),
			},
			makeUSD: false,
            wantSubscription: false,
            hasLicense: false,
            license: null,
            moment: moment
        }
    },

    computed: {
    	total() {
    		return this.line.qty * this.line.unit;
    	}
    },

    watch: {
        total: function(val) {
            this.line.total = val;
            this.doUpdate();
        },

        line: {
        	handler: function(val, oldval) {
        		this.doUpdate();
        	}, deep: true
    	}
    },

    methods: {
        selectedProduct(value) {
        	this.fillInfoFromLineDesc();
        	this.doUpdate();
        },

        isSubscription() {
            return (parseInt(this.line.license_id) > 0 || this.wantSubscription);
        },

        fillInfoFromLineDesc() {
        	self = this;
            if (this.prodList == undefined || _.isEmpty(this.prodList)) return;
        	let found = this.prodList.find(function(product) {
        		return (product.text == self.line.line_desc);
        	});
        	if (! found || found == undefined) {
        		if (this.line.line_desc == '') {
        			this.line.code = '';
        			this.line.qty = 1;
        			this.line.unit = 0;
        		}
        		return;
        	}
        	this.line.qty = (this.line.qty < 1) ? 1 : this.line.qty;
    		this.doCalculatedFill(found);
        },

        doCalculatedFill(productItem) {
        	this.line.code = productItem.code;
        	switch (productItem.code) {
        		case 'SDSS': 
        		case 'SDO': this.line.unit = 370; this.makeUSD = true; break;

        		case 'SDSS-S':
        		case 'SDO-S': this.line.unit = 2775; this.makeUSD = true; break;

        		case 'SDSS-R':
        		case 'SDO-R': this.line.unit = 185; this.makeUSD = true; break;

        		case 'SDSS-SR':
        		case 'SDO-SR': this.line.unit = 1387.50; this.makeUSD = true; break;

        		case 'SDSS-RC':
        		case 'SDO-RC': this.line.unit = 296; this.makeUSD = true; break;

        		case 'SDSS-SRC':
        		case 'SDO-SRC': this.line.unit = 2220; this.makeUSD = true; break;

        		case 'SDMY': this.line.unit = 185; this.makeUSD = true; break;
        		case 'SDMY-S': this.line.unit = 1395; this.makeUSD = true; break;
        		case 'SDMY-R': this.line.unit = 92.50; this.makeUSD = true; break;
        		case 'SDMY-SR': this.line.unit = 697.50; this.makeUSD = true; break;
        		case 'SDMY-RC': this.line.unit = 148; this.makeUSD = true; break;
        		case 'SDMY-SRC': this.line.unit = 1116; this.makeUSD = true; break;

        		case 'DXPSM':
        		case 'DXPOM': this.line.unit = 460; this.makeUSD = true; break;

        		case 'DXPSM-S':
        		case 'DXPOM-S': this.line.unit = 3450; this.makeUSD = true; break;

        		case 'DXPSM-R':
        		case 'DXPOM-R': this.line.unit = 230; this.makeUSD = true; break;

        		case 'DXPSM-SR':
        		case 'DXPOM-SR': this.line.unit = 1725; this.makeUSD = true; break;

        		case 'DXPSM-RC':
        		case 'DXPOM-RC': this.line.unit = 368; this.makeUSD = true; break;

        		case 'DXPSM-SRC':
        		case 'DXPOM-SRC': this.line.unit = 2760; this.makeUSD = true; break;

        		case 'DXPPM': this.line.unit = 530; this.makeUSD = true; break;
        		case 'DXPPM-S': this.line.unit = 3975; this.makeUSD = true; break;
        		case 'DXPPM-R': this.line.unit = 265; this.makeUSD = true; break;
        		case 'DXPPM-RC': this.line.unit = 424; this.makeUSD = true; break;
        		case 'DXPPM-SR': this.line.unit = 1987.50; this.makeUSD = true; break;
        		case 'DXPPM-SRC': this.line.unit = 3180; this.makeUSD = true; break;

        		case 'NURX-SUP': 
        			this.line.unit = 2500; 
        			this.makeUSD = false; 
        			break;
        		case 'NURX-WEB': 
        			this.line.unit = 600; 
        			this.makeUSD = false; 
        			break;
        	}
            this.line.originalUnit = this.line.unit;
            this.doUpdateUnit();
        },

        doUpdate() {
            // It's possible the invoice item is non standard so set the code as GEN and allow to continue
            this.line.code = (this.line.code == '') ? 'GEN' : this.line.code;
            this.$emit("line-changed", {
                action: 'save',
                index: this.line.row_position,
                line: this.line,
                makeUSD: this.makeUSD,
                license: this.license
            });
        },

        findLicense() {
            this.hasLicense = false;
            if (parseInt(this.line.license_id) == 0) {
                return;
            }
            axios.post("/order/getinvoice", {'license_id': this.line.license_id})
                .then(response => {
                    if (! _.isEmpty(response.data.license)) {
                        this.license = response.data.license;
                        this.hasLicense = true;
                        this.line.qty = this.license.qty;
                        this.line.license_id = this.license.id
                        this.fillInfoFromLineDesc();
                    } else {
                        flash("Could not find that license");
                    }
                }).catch(error => {
                    console.log(error.response);
                    flash("Internal error");
                })
        },

        deleteRow(index) {
            this.$emit("line-changed", {
                action: 'delete',
                index: index,
                line: []
            });
        },

		doUpdateUnit() {
			const qtyVal = parseInt(this.line.qty);

			if (isNaN(qtyVal)) return;

			const std = ['SDSS', 'SDO'];
			const xp = ['DXPSM', 'DXPOM'];
			let productCode = this.line.code;
			let subsCode = '';
			if (this.line.code.split("-").length > 1) {
				productCode = this.line.code.split("-")[0];
				subsCode = this.line.code.split("-")[1];
			}
			if (qtyVal >= 10) {
				if (std.indexOf(productCode) > -1) {
					this.line.unit = 185;
				} else if (xp.indexOf(productCode) > -1) {
					this.line.unit = 230;
				} else if (productCode == 'DXPPM') {
					this.line.unit = 265;
				} else if (productCode == 'SDMY') {
					this.line.unit = 92;
				}
				// {"unit":370,"unit5":204,"unit10":185,"unit25":185,"site":2775}
			} else if (qtyVal >= 5) {
				if (std.indexOf(productCode) > -1) {
					this.line.unit = 204;
				} else if (xp.indexOf(productCode) > -1) {
					this.line.unit = 253;
				} else if (productCode == 'DXPPM') {
					this.line.unit = 292;
				} else if (productCode == 'SDMY') {
					this.line.unit = 102;
				}
			} else {
                if (std.indexOf(productCode) > -1) {
                    this.line.unit = 370;
                } else if (xp.indexOf(productCode) > -1) {
                    this.line.unit = 460;
                } else if (productCode == 'DXPPM') {
                    this.line.unit = 530;
                } else if (productCode == 'SDMY') {
                    this.line.unit = 185;
                }
            }
            this.wantSubscription = false;
			if (subsCode != '') {
                if (subsCode.includes('S') === true) {
                    if (std.indexOf(productCode) > -1) {
                        this.line.unit = 2775;
                    } else if (xp.indexOf(productCode) > -1) {
                        this.line.unit = 3450;
                    } else if (productCode == 'DXPPM') {
                        this.line.unit = 3975;
                    } else if (productCode == 'SDMY') {
                        this.line.unit = 1395;
                    }
                } 
                this.line.originalUnit = this.line.unit;
                if (subsCode.includes('RC') === true) {
                    this.wantSubscription = true;
					this.line.unit = _.round(this.line.originalUnit * 0.8,2);
				} else if (subsCode.includes('R') === true) {
                    this.wantSubscription = true;
					this.line.unit = _.round(this.line.originalUnit * 0.5,2);
				}
			}
		},

        getFormattedDecimal(value) {
            const formatter = new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 0
            });
            return formatter.format(value);
        },

        showLicense() {
            if (! this.hasLicense) return "";
            let $reg = this.license.regcode.split('-');

            return this.license.regname + 
                    " expires: " + 
                    this.moment(this.license.subscription.ends_at).format('DD-MMM-YYYY') +
                    " for "+this.license.productdesc +
                    " with reg ending " + _.last($reg);
        },

        getQtyClass() {
            if (! this.wantSubscription || ! this.hasLicense) return "";
            return (this.license.qty != this.line.qty) ? "border-red" : "";
        }

    }
}

</script>