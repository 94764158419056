require('./bootstrap');

window.events = new Vue();
window.flash = function(message, level = 'success') {
    window.events.$emit('flash', { message, level });
};

Vue.component("flash", require("./components/Flash.vue").default);
Vue.component('invoicing', require('./components/Invoicing.vue').default);
Vue.component('creditcard', require('./components/CreditCard.vue').default);

const app = new Vue({
    el: '#app',
});