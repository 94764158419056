<template>
<div class="col-8 border border-grey-light mt-4">
	<div class="row">
		<div class="col-12 bg-grey-light border-b border-grey d-flex align-items-center" style="height: 50px">
			Subscription Renewal
		</div>
	</div>
	<div class="row" v-if="hasLicense">
		<div class="col-12 bg-grey-lightest py-4">
			<div class="row">
				<div class="col-3">Registered User:</div>
				<div class="col-9">{{ license.regname}}</div>
			</div>
			<div class="row">
				<div class="col-3">Current Expiry:</div>
				<div class="col-9">{{ moment(license.subscription.ends_at).format('DD-MMM-YYYY')}}</div>
			</div>
			<div class="row">
				<div class="col-3">Type:</div>
				<div class="col-9">{{ renewalTypeText }}</div>
			</div>
			<div class="row">
				<div class="col-3">Renews To:</div>
				<div class="col-9">{{ renewalTo }}</div>
			</div>
			<div class="row">
				<div class="col-3">For:</div>
				<div class="col-9">{{ license.regcode }}</div>
			</div>
		</div>
	</div>
	<div class="row" v-else>
		<div class="col-12">
			Loading license details...
		</div>
	</div>
	<div class="row">
		<div class="col-12 bg-grey border-t border-grey d-flex align-items-center" style="height: 50px">
			<div class="col-12 d-flex justify-content-end">
				<button class="btn btn-primary" v-if="hasLicense && leaveText == 'Cancel'" @click.prevent="doSave">Renew</button>
				<button class="btn btn-secondary ml-3" @click.prevent="doCancel" v-text="leaveText"></button>
			</div>
		</div>
	</div>	
</div>
</template>

<script>
import _ from 'lodash'
var moment = require('moment');

export default {

    data() {
        return {
            masterInvoice: {},
            masterReseller: {},
            hasLicense: false,
            license: {},
            renewalType: '',
            renewalTypeText: '',
            renewalTo: '',
            moment: moment,
            leaveText: 'Cancel'
        }
    },

    mounted() {
        window.events.$on("sub_reload", data => {
            this.doReload();
        });
        this.doReload();

    },

    methods: {
        doReload() {
            this.license = {};
            this.hasLicense = false;
            this.masterInvoice = {};
            this.masterReseller = {};
            if (! _.isEmpty(this.$parent.masterInvoice)) {
                this.masterInvoice = this.$parent.masterInvoice;

            }
            if (! _.isEmpty(this.$parent.masterReseller)) {
                this.masterReseller = this.$parent.masterReseller;
            }
            this.findLicense();
        },

        findLicense() {
            this.hasLicense = false;
            if (! _.isInteger(this.masterInvoice.delta_license_id)) {
                return;
            }
            axios.post("/order/getinvoice", {'license_id': this.masterInvoice.delta_license_id})
                .then(response => {
                    if (! _.isEmpty(response.data.license)) {
                        this.license = response.data.license;
                        this.hasLicense = true;
                        this.calculateSub();
                    }
                });
        },

        calculateSub() {
        	let code = this.masterInvoice.invoice_lines[0].code.split('-');
        	if (code[1] == 'RC' || code[1] == 'SRC') {
        		this.renewalType = 'RC';
        		this.renewalTypeText = 'Return Customer Subscription Renewal';
        		this.renewalTo = moment().add(2,'y').format('DD-MMM-YYYY');
        	} else if (code[1] == 'R' || code[1] == 'SR') {
        		this.renewalType = 'R';
        		this.renewalTypeText = 'Subscription Renewal';
        		this.renewalTo = moment(this.license.subscription.ends_at).add(2,'y').format('DD-MMM-YYYY');
        	}
        },

        doSave() {
            axios.post("/order/renewal", {
	            'license_id': this.license.id,
    	        'renewal_type': this.renewalType,
    	        'reference': this.masterInvoice.reference,
    	        'invnum': this.masterInvoice.invnum,
    	        'reseller_id': this.masterInvoice.reseller_id
            }).then(response => {
                flash(response.data.message);
                this.leaveText = 'Done';
                this.license = response.data.license;
				this.calculateSub();
            }).catch(error => {
                console.log(error.response.data);
                flash('Failed to create subscription');
            });
        },

        doCancel() {
            this.$emit("form-action", {
                action: 'invoice-list'
            });
        },

	}
}	
</script>