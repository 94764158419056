<template>
    <div class="col-12">
        <div class="row" v-if="hasCompany">
            <div class="col-5">
                <div class="card">
                    <div class="card-header">
                        <div class="col-12" >
                            <span v-if="isVoid">Void the Invoice to {{ masterInvoice.customer.company }}</span>
                            <span v-else>Record a Payment from {{ masterInvoice.customer.company }}</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-row align-items-center">
                            <div class="col-12 ml-3 mb-3">
                                <div>{{ masterInvoice.customer.firstname }} {{ masterInvoice.customer.lastname }}</div>
                                <div>{{ masterInvoice.customer.addr1 }}</div>
                                <div>{{ masterInvoice.customer.addr2 }}</div>
                                <div>{{ masterInvoice.customer.suburb }} {{ masterInvoice.customer.state }} {{ masterInvoice.customer.postcode }}</div>
                                <div>{{ masterInvoice.customer.country }} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-7">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6">
                                Invoice {{ form.invnum }} on {{ moment(masterInvoice.created_at).format('DD-MMM-YYYY') }}
                            </div>
                            <div class="col-6 d-flex justify-content-end">
                                {{ form.ponumber }}
                            </div>
                        </div>                        
                    </div>
                    <div class="card-body">
                        <div class="row align-items-center" v-for="line in masterInvoice.invoice_lines">
                            <div class="col-8">{{ line.line_desc }}</div>
                            <div class="col-1 text-right">{{ line.qty }}</div>
                            <div class="col-3 text-right">{{ line.total }}</div>
                        </div>
                        <div class="row border-t border-grey-light mt-2 align-items-center pt-3">
                            <div class="col-4">Discount: {{ masterInvoice.discount_value }}</div>
                            <div class="col-4">Tax: {{ masterInvoice.tax_value }}</div>
                            <div class="col-4 text-right">Grand Total: {{ masterInvoice.total }}</div>
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-header">
                        <div class="row">
                            <div class="col" v-if="isVoid">
                                Void Invoice Payment
                            </div>
                            <div class="col" v-else>
                                Invoice Payment Received
                            </div>
                        </div>                        
                    </div>
                    <div class="card-body">
                            <div class="col-12" v-if="isVoid">
                                <select class="form-control form-control-sm" v-model="form.paid_how">
                                    <option v-for="action in getVoidOptions(0)" :value="action.value">{{ action.text }}</option>
                                </select>
                            </div>
                            <div class="col-12" v-else>
                                <select class="form-control form-control-sm" v-model="form.paid_how">
                                    <option v-for="action in getPayOptions(0)" :value="action.value">{{ action.text }}</option>
                                </select>
                            </div>
                            <div class="col-12 mt-3">
                                <div class="form-group row">
                                    <label class="col-6 col-form-label" for="amount">Payment Amount {{ getCurrency() }}</label>
                                    <div class="col-6 text-right">
                                        <input class="form-control form-control-sm text-right" type="text" name="amount" id="amount" v-model="form.amount">
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row bg-grey-lightest border mt-2 rounded ">
            <div class="col-2">
                <div class="mr-3" v-if="isVoid">
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                        type="button" 
                        @click.prevent="doSwap"><i class="fal fa-random mr-2"></i>As Payment</button>
                </div>
                <div class="mr-3" v-else>
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                        type="button" 
                        @click.prevent="doSwap"><i class="fal fa-random mr-2"></i>As Void</button>
                </div>
            </div>
            <div class="col-10 d-inline-flex justify-content-end">
                <div class="mr-3" v-if="isVoid">
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                        type="button" 
                        @click.prevent="doSave"><i class="fal fa-save mr-2"></i>Void this Invoice</button>
                </div>
                <div class="mr-3" v-else>
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                        type="button" 
                        @click.prevent="doSave"><i class="fal fa-save mr-2"></i>Record Payment</button>
                </div>
                <div class="">
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                        type="button" 
                        @click.prevent="doCancel"><i class="fal fa-arrow-alt-right mr-2"></i>Return</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import _ from 'lodash'

var moment = require('moment');

export default {

    data() {
        return {
            masterInvoice: {},
            masterReseller: {},
            form: {},
            license: {},
            moment: moment,
            hasLicense: false,
            hasCompany: false,
            isVoid: false
        }
    },

    mounted() {
        window.events.$on("payment_reload", data => {
            this.doReload();
        });
        this.doReload();

    },

    methods: {
        doReload() {
            this.license = {};
            this.hasLicense = {};
            this.masterInvoice = {};
            this.masterReseller = {};
            if (! _.isEmpty(this.$parent.masterInvoice)) {
                this.masterInvoice = this.$parent.masterInvoice;
                this.setFormData();
            }
            if (! _.isEmpty(this.$parent.paymentAction)) {
                this.isVoid = this.form.makeVoid = (this.$parent.paymentAction == 'void');
                if (this.form.makeVoid) {
                    this.form.paid_how = 0;
                    this.form.amount = 0;
                }
            }
            this.loadOrder();
            this.loadReseller();
        },

        setFormData() {
            this.form.qty = this.masterInvoice.invoice_lines[0].qty;
            this.form.productcode = this.masterInvoice.invoice_lines[0].code;
            this.form.license_id = this.masterInvoice.invoice_lines[0].license_id;
            this.form.unit = this.masterInvoice.invoice_lines[0].unit;
            this.form.reseller_id = this.masterInvoice.reseller_id;
            this.form.ponumber = this.masterInvoice.reference;
            this.form.billemail = this.masterInvoice.customer.email;
            this.form.invnum = this.masterInvoice.invnum;
            this.form.amount = this.masterInvoice.total;
            this.form.paid_how = 2;
            this.hasCompany = true;
        },

        loadOrder() {
            axios.post('order/getinvoice', {invnum: this.form.invnum})
                .then(response => {
                    this.license = response.data.license;
                    this.hasLicense =  (this.license != null);
                });
        },

        loadReseller() {
            if (this.masterInvoice.reseller_id != 0) {
                axios.post('/reseller/get', {reseller_id: this.masterInvoice.reseller_id})
                    .then(response => {
                        this.masterReseller = response.data;
                });
            }
        },

        getPayOptions(index) {
            return [
                {value: 0, text: "Choose.."},
                {value: 2, text: "Funds Transfer to Bank"},
                {value: 3, text: "Cheque"},
                {value: 4, text: "Paypal"},
                {value: 1, text: "Credit card"}
            ]
        },

        getVoidOptions(index) {
            return [
                {value: 0, text: "Choose.."},
                {value: 1, text: "Alternate payment"},
                {value: 2, text: "Requested an Invoice and didn't pay"},
                {value: 3, text: "Overdue for too long"},
                {value: 4, text: "Other reason"}
            ]
        },

        getCurrency() {
            return (this.masterInvoice.currency == 1) ? "AUD" : "USD";
        },

        doSave() {
            axios.post("/invoice/pay", this.form)
                .then(response => {
                    flash(response.data.message);
                })
                .catch(error => {
                    console.log(error);
                });
        },

        doCancel() {
            this.$emit("form-action", {
                action: 'invoice-list'
            });
        },

        doSwap() {
            this.isVoid = this.form.makeVoid = ! this.form.makeVoid;
            this.$forceUpdate();
        }


    }
}
</script>
