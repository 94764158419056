<template>
    <div :class="classes"
         style="right: 55px; bottom: 125px; position:absolute; z-index:2000;"
         role="alert"
         v-show="show"
         v-text="body">
    </div>
</template>

<script>
    export default {
        props: ['message'],

        data() {
            return {
                body: this.message,
                level: 'success',
                show: false
            }
        },

        computed: {
            classes() {
                let defaults = ['alert shadow'];

                if (this.level === 'success') defaults.push('alert-success', 'border-green-dark');
                if (this.level === 'warning') defaults.push('alert-warning', 'border-yellow-dark');
                if (this.level === 'danger') defaults.push('alert-danger', 'border-red-dark');

                return defaults;
            }
        },

        created() {
            if (this.message) {
                this.flash();
            }

            window.events.$on(
                'flash', data => this.flash(data)
            );
        },

        methods: {
            flash(data) {
                if (data) {
                    this.body = data.message;
                    this.level = data.level;
                }

                this.show = true;

                this.hide();
            },

            hide() {
                setTimeout(() => {
                    this.show = false;
                }, 7000);
            }
        }
    };
</script>
