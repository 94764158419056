<template>
    <ul v-if="shouldPaginate" class="pagination">
        <li v-show="prevUrl" class="mr-3 ">
            <a class="border border-grey rounded p-2 bg-grey-lighter" href="#" aria-label="Previous" rel="prev" @click.prevent="page--">
                <span class="" aria-hidden="true"><i class="far fa-angle-left mr-1"></i>Previous</span>
            </a>
        </li>

        <li v-show="nextUrl" class="">
            <a href="#" class="border border-grey rounded p-2 bg-grey-lighter" aria-label="Next" rel="next" @click.prevent="page++">
                <span class="" aria-hidden="true">Next<i class="far fa-angle-right ml-1"></i></span>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    props: ['dataSet'],

    data() {
        return {
            page: 1,
            prevUrl: false,
            nextUrl: false
        };
    },

    watch: {
        dataSet() {
            this.page = this.dataSet.current_page;
            this.prevUrl = this.dataSet.prev_page_url;
            this.nextUrl = this.dataSet.next_page_url;
        },

        page() {
            this.broadcast().updateUrl();
        }
    },

    computed: {
        shouldPaginate() {
            return !!this.prevUrl || !!this.nextUrl;
        }
    },

    methods: {
        broadcast() {
            return this.$emit('changed', this.page);
        },

        updateUrl() {
            history.pushState(null, null, '?page=' + this.page);
        }
    }
};
</script>
