<template>
    <div class="col-12 col-md-10 col-lg-8">
        <form method="post" autocomplete="off" @submit.prevent="doNext" @keydown="form.clearErrors($event.target.name)">
            <div class="card">
                <div class="card-header">Invoice Customer</div>
                <div class="card-body">
                    <div class="form-row align-items-center">
                        <div class="col-2 col-1">
                         <label for="email">Email:</label>
                        </div>
                        <div class="col">
                            <div class="input-group">
                                <b-form-input
                                    type="search"
                                    id="email" 
                                    name="email"
                                    list="emailList" 
                                    @input="loadEmail"
                                    @change="doEmailComplete"
                                    @blur="doEmailComplete"
                                    v-model="form.email" 
                                    tabindex=1
                                    autocomplete="off"
                                    autofocus>
                                </b-form-input>
                                <div class="input-group-append" v-if="form.getLoading">
                                    <span class="btn btn-primary"><i class="fad fa-spinner " :class="form.getLoading ? 'fa-spin' : ''"></i></span>
                                </div>
                                <div class="input-group-append" v-else>
                                    <button class="btn btn-primary" 
                                            type="button" 
                                            v-b-tooltip="{placement: 'top', delay: {'show': 750, 'hide': 100}, variant:'info'}"
                                            role="tooltip"
                                            title="Click find and load existing contact or will clear if they dont exist"
                                            @click.prevent="forceEmailComplete(true)"
                                            id="button-addon2"><i class="fal fa-file-check"></i></button>
                                </div>
                            </div>
                                <datalist id="emailList">
                                    <option v-for="email in form.emailData">{{ email }}</option>
                                </datalist>                                    
                            <div class="text-grey-dark text-xs mt-2">New customer email or search for email or name of existing customer</div>
                            <span class="text-red text-sm" v-if="form.hasErrors('email')" v-text="form.errors.get('email')"></span>
                        </div>
                    </div>
                </div>
            </div>
            <contact :contact="form"></contact>
            <div class="col-12 bg-white border mt-2 rounded d-inline-flex justify-content-end" v-if="! form.getFoundStatus()">
                <div class="mr-2">
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded px-2" 
                        type="button" 
                        :disabled="form.anyErrors() || form.email == ''"
                        @click.prevent="doSave"><i class="fal fa-save mr-2"></i>Save</button>
                </div>
                <div class="">
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                        type="submit" 
                        :disabled="form.anyErrors() || notOkForNext()"
                        @submit.prevent="doNext"><i class="fal fa-arrow-alt-right mr-2"></i>Next</button>
                </div>
            </div>
            <div class="col-12 bg-white border mt-2 rounded d-inline-flex justify-content-end" v-if="form.getFoundStatus()">
                <div class="mr-2">
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                        type="button" 
                        :disabled="form.anyErrors()"
                        @click.prevent="doSave()"><i class="fal fa-save mr-2"></i>Update</button>
                </div>
                <div class="">
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                        type="submit" 
                        :disabled="form.anyErrors() || notOkForNext()"
                        @submit.prevent="doNext"><i class="fal fa-arrow-alt-right mr-2"></i>Next</button>
                </div>
            </div>

        </form>
    </div>
</template>

<script>

import ContactForm from './contact/contactform.js';
import Countries from './contact/countries.js';
import contact from './contact/Contact'
import _ from 'lodash'

export default {
    components: {
        contact
    },

    data() {
        return {
            form: new ContactForm({
                customer_id: 0,
                email:'',
                company: '',
                firstname: '',
                lastname: '',
                password:'',
                confirmpassword: '',
                is_company: true,
                is_reseller: true,
                tax: 0,
                currency: 0,
                addr1 : '',
                addr2 : '',
                suburb: '',
                countrydata: new Countries({
                    country: '',
                    countrycode: '',
                    state: '',
                    statecode: '',
                    postcode: '',
                    vat:'',
                    vat_id:''
                })
            }, {
                showVat: true,
                showPassword: false,
                showIsCompany: true,
                showCurrency: true,
                showIsReseller: true,
                showHasTax: true,
                title: 'Billing Name and Address'
            }),
            customerSaved: false
        }
    },

    methods: {
        // suggest from server email(s) based on input value 
        loadEmail(value) {
            this.form.setFoundStatus(false);
            this.form.listByEmail(value, '/customer/find');
        },

        // either a new email or load the details ready for updating the customer
        emailComplete(force) {
            if (force || ! this.form.getFoundStatus()) {
                this.form.get('/customer/retrieve')
                    .then(data => {
                        if (! _.isEmpty(data) || force) {
                            this.fillFoundContact(data);
                        }
                    });      //.then - all handled withn get && .catch -  Errors in retrieve dont matter 
            }
        },

        forceEmailComplete() {
            this.emailComplete(true);
        },

        doEmailComplete() {
            this.emailComplete(false);
        },

        // because the form data is somewhat dynamic it must be updated in the the vue and not form object
        fillFoundContact(data) {
            this.form.copyData(data);
            // IMPORTANT! the copyData doesn't copy customer id because within data its id and this uses customer_id
            this.form.customer_id = data.id;
        },

        notOkForNext() {
            return ! this.form.getFoundStatus() && (this.form.email == '' || this.form.countrydata.country === '' && this.form.suburb === '');
        },

        // Just save/update the customer details
        doSave() {
            this.save(false);
        },

        // Move to the next page, saving if new customer
        doNext() {
            if (! this.customerSaved && ! this.form.getFoundStatus()) {
                this.save(true);
            } else {
                this.gotoNextPage();
            }
        },

        save(toReseller = false) {
            let action = this.form.getFoundStatus() ? "put" : "post";
            this.form[action]('/customer')
                .then(data => {
                    flash(data.message);
                    this.form.customer_id = data.customer.id;
                    this.customerSaved = true;
                    if (toReseller) {
                        this.gotoNextPage();
                    }
                }).catch(errors => {
                });
        },


        gotoNextPage() {
            this.$emit("form-action", {
                action: 'reseller',
                form: this.form
            });

        }

    }
}

</script>
