import _ from 'lodash';
import Errors from './formerrors.js';
import Countries from './countries.js';
import BaseForm from './baseform.js';

class ContactForm extends BaseForm
{
    constructor(data, options)
    {
        super(data, options);

        // to make accessing the sub component Countries easier then store a reference here 
        // and if it isn't passed through and therefore not used then manually create so functions 
        // referenceing countries dont fail
        this.__countries = null;

        for (let field in data) {
            this[field] = data[field];
            if (data[field] instanceof Countries) {
                this.__countries = data[field];
            }
        }

        if (! this.__countries) {
            this.__countries = new Countries({});
        }

        this.emailData = {};

        this.setListByEmail();

    }

    showVAT() {
        return (this.options.showVat == undefined || this.options.showVat == null) ? true : this.options.showVat;
    }

    showPassword() {
        return (this.options.showPassword == undefined || this.options.showPassword == null) ? false : this.options.showPassword;
    }

    showIsCompany() {
        return (this.options.showIsCompany == undefined || this.options.showIsCompany == null) ? true : this.options.showIsCompany;
    }

    showTitle() {
        return  (this.options.title == undefined || 
                this.options.title == null ||
                this.options.title === '') ? false : true;
    }

    /**
    * Extract the data from the object to post/put
    */
    extractData() {
        let data = {};
        for (let property in this.originalData) {
            if (typeof this[property] == 'object' && this[property] instanceof Countries) {
                data[property] = this[property].extractData();
            } else {
                data[property] = this[property];
            }
        }
        data.options = this.options;
        return data;
    }

    onSuccess(data) {
        this.setFoundStatus(true);
    }

    onFail(errors) {
        super.onFail(errors);
        this.__countries.errors.record(errors);
    }

    /**
    * Validation prior to calling the get Axios post 
    * returns true if validation passed and false to stop the call
    */
    beforeGetPost() {
        if (! this.email || this.email == '')  {
            if (this.company != '') {
                this.reset();
            }
            return false;
        }
        return true;
    }

    /**
    * When the get completes the function is called to allow child object to pre-configure data before resolve
    */
    beforeGetResolve(data) {
        if (! _.isEmpty(data)) {
            this.setFoundStatus(true);
            this.emailData = {};
        } else {
            this.setFoundStatus(false);
        }
    }

    /**
    * Clear all the fields and reset things like errors
    * Cannot call parent because it breaks countrydata
    */
    reset(ignoreEmail = false) {
        this.setFoundStatus(false);
        this.generalmsg = "";
        this.emailData = {};
        this.__countries.reset();
        for (let field in this.originalData) {
            // dont want to reset booleans and the countries object to string so ignore those plus sometimes dont want to reset email
            if (this[field] != undefined && ! this.booleans.includes(field) && ! (this[field] instanceof Countries)) {
                if (! ignoreEmail || field !== 'email') {
                    this[field] = "";
                }
            }
        }
        this.errors.clear();
    }

    getErrors(field) {
        let result = super.getErrors(field);
        if (! result) {
            return this.__countries.errors.get("countrydata." + field);   
        } 
        return result;
    }

    anyErrors() {
        if (super.anyErrors()) return true;
        return this.__countries.errors.any();

        return (this.errors.any() && this.__countries.errors.any());
    }

    clearErrors(field) {
        super.clearErrors(field);
        this.__countries.errors.clear("countrydata." + field);   
    }

    hasErrors(field) {
        let result = super.hasErrors(field);
        if (! result) {
            result = this.__countries.errors.has("countrydata." + field);   
        } 
        return result;
    }

    setListByEmail() {
        this.listByEmail = _.debounce(function(value, url) {
            // Has the user cleared the email so reset the whole form

            if (value == '' && this.company != '')  {
                this.reset();
            }

            // the search request must be more than 4 chars
            if (value < 4) {
                return;
            }
        
            axios.post(url, {email: value})
                .then(response => {
                    this.emailData = response.data;
                });
        }, 250);
    }


}

export default ContactForm;