<template>
    <div class="row justify-content-center">
		<keep-alive>
			<component v-bind:is='currentTab' @form-action="doFormAction"></component>
		</keep-alive>
	</div>
</template>

<script>
import invoicelist from './InvoiceList.vue';
import customer from './Customer.vue';
import reseller from './Reseller.vue';
import invoice from './Invoice.vue';
import finalise from './Finalise.vue';
import deltauser from './DeltaUser.vue';
import deltasub from './DeltaSubscription.vue';
import status from './Status.vue';
import payment from './Payment.vue';
import ContactForm from './contact/contactform.js';
import Countries from './contact/countries.js';

export default {

	components: {
		'invoice-list': invoicelist,
		'customer': customer,
		'reseller': reseller,
		'invoice': invoice,
		'finalise': finalise,
		'deltauser': deltauser,
		'deltasub': deltasub,
		'invstatus': status,
		'payment': payment
	},

	data() {
		return {
			currentTab: 'invoice-list',
			masterForm: {},
			masterReseller: {},
			masterInvoice: {},
			wantEditInvoice: false,
			refreshInvoiceList: false,
			paymentAction: ""
		}
	},

	methods: {
		doFormAction(value) {
			switch (value.action) {
				case 'invoice-list': this.toInvoiceList(); break;
				case 'customer': this.toCustomer(); break;
				case 'reseller': this.toReseller(value.form); break;
				case 'invoice': this.toInvoice(value.form); break;
				case 'final': this.toFinal(value); break;
				case 'deltauser': this.toDeltaUser(value); break;
				case 'deltasub': this.toDeltaSub(value); break;
				case 'editinvoice': this.editInvoice(value); break;
				case 'invstatus': this.toStatus(value); break;
				case 'payment': this.toPayment(value); break;
			}
		},

		toInvoiceList() {
			if (this.wantEditInvoice) {
				this.wantEditInvoice = false;
			}
			window.events.$emit("reload");
			this.currentTab = 'invoice-list';
		},

		toCustomer() {
			this.masterInvoice = {};
			this.masterReseller = {};
			this.wantEditInvoice = false;
			window.events.$emit("customer_reload");
			this.currentTab = 'customer';
		},

		toReseller(customerForm) {
			this.masterForm = customerForm;
			window.events.$emit("reseller_reload");
			this.currentTab = 'reseller';
		},

		toInvoice(resellerForm) {
			this.masterInvoice = {};
			this.masterReseller = resellerForm;
			this.wantEditInvoice = false;
			window.events.$emit("invoice_reload");
			this.currentTab = 'invoice';
		},

		editInvoice(forms) {
            this.masterForm = new ContactForm({
                        customer_id: 0,
                        email:'',
                        company: '',
                        firstname: '',
                        lastname: '',
                        password:'',
                        confirmpassword: '',
                        is_company: true,
                        is_reseller: true,
                        tax: 0,
                        currency: 0,
                        addr1 : '',
                        addr2 : '',
                        suburb: '',
                        countrydata: new Countries({
                            country: '',
                            countrycode: '',
                            state: '',
                            statecode: '',
                            postcode: '',
                            vat:''
                        })
                    }, {
                        showVat: true,
                        showPassword: false,
                        showIsCompany: false,
                        showCurrency: false,
                        showIsReseller: false,
                        showHasTax: false,
                        title: ''
                    });
            this.masterForm.copyData(forms.form.customer, true);
			this.masterInvoice = forms.form;
			this.masterReseller = forms.reseller;
			this.wantEditInvoice = true;
			window.events.$emit("invoice_reload");
			this.currentTab = 'invoice';
		},

		toFinal(invoiceForm) {
			this.masterInvoice = invoiceForm.form;
			this.masterReseller = invoiceForm.reseller;
			this.currentTab = 'finalise';
		},

		toDeltaUser(forms) {
			this.masterInvoice = forms.form;
			this.masterReseller = forms.reseller;
			window.events.$emit("order_reload");
			this.currentTab = 'deltauser';
		},

		toDeltaSub(forms) {
			this.masterInvoice = forms.form;
			this.masterReseller = forms.reseller;
			window.events.$emit("sub_reload");
			this.currentTab = 'deltasub';
		},

		toStatus(forms) {
			this.masterInvoice = forms.form;
			this.masterReseller = forms.reseller;
			window.events.$emit("status_reload");
			this.currentTab = 'invstatus';
		},

		toPayment(forms) {
			this.masterInvoice = forms.form;
			this.masterReseller = forms.reseller;
			this.paymentAction = forms.mode;
			window.events.$emit("payment_reload");
			this.currentTab = 'payment';
		},

	}
}

</script>





