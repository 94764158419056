class Errors
{
	/**
	* Manages the validation error messages generally returned from the server
	*/
    constructor() {
        this.errors = {};
    }

    /**
    * Store the errors
    */
    record(errors) {
        this.errors = errors;
    }

    /**
    * Return the error message for the field from the errors "bag"
    * Some errors could be from countrydata.country, etc so remove the unwanted text
    * in future might want and word preceding the . however not everything since the message is The countrydata.country is required.
    */
    get(field) {
        if (this.errors[field]) {
            return this.errors[field][0].replace("countrydata.", "");
        }
    }

    /**
    * Does the field have any errors, if so then show the error div/span
    */
    has(field) {
        if (this.errors == undefined) return false;
		return this.errors.hasOwnProperty(field);
    }

    /**
    * Are there any errors at all
    */
    any() {
    	return Object.keys(this.errors).length > 0;
    }

    /**
    * remove an error or all errors
    */
    clear(field) {
        if (this.errors[field]) {
	    	delete this.errors[field];
            return;
	    }
	    this.errors = {};
    }

}

export default Errors;
