<template>
    <div class="col-12">
    	<div class="form-row mb-4 pt-1 align-items-center border-t border-grey-light">
    		<div class="col-2">
    			<a class="border border-grey rounded py-1 px-2" :class="styleUnpaid" href="#" @click.prevent="setStyleFilter('unpaid')">Unpaid</a>
    			<a class="border border-grey rounded py-1 px-2" :class="stylePaid" href="#" @click.prevent="setStyleFilter('paid')">Paid</a>
    		</div>
    		<div class="col-3">
    			<div class="input-group">
    				<input type="text" name="search" class="form-control form-control-sm" v-model="filter">
    				<a class="input-group-append border border-grey rounded bg-grey-lighter px-2 pt-2" href="#"><i class="fas fa-search"></i></a>
					<a class="input-group-append border border-grey rounded bg-grey-lighter px-2 pt-2" 
					   href="#" @click.prevent="clearFilter" 
					   v-if="filter != ''"><i class="fas fa-times"></i></a>
    			</div>
    		</div>
    		<div class="col-7 d-flex justify-content-end">
    			<a class="border border-grey rounded py-1 px-2 bg-grey-lighter mr-2" href="#" @click.prevent="doNewInvoice">New Invoice</a>
    		</div>
    	</div>
    	<div class="container-fluid mb-3 no-gutters">
    		<b-table
    			class="w-100"
				hover 
				sticky-header
				stacked="sm"
				outlined
				selectable
				select-mode="single"
				@row-selected="doRowSelect"
				:items="items" 
				:fields="fields" >
				<template v-slot:cell(created_at)="item">
					<span :class="getActionClass(item)">{{ moment(item.item.created_at).format('MMMM DD, YYYY') }}</span>
				</template>
				<template v-slot:cell(invnum)="item">
					<span :class="getActionClass(item)">{{ item.value }}</span>
				</template>
				<template v-slot:cell(customer.company)="item">
					<div :class="getActionClass(item)">{{item.value}}</div>
					<div class="text-xs" :class="getActionClass(item)" v-html="getInvoiceItems(item)"></div>
				</template>
				<template v-slot:cell(show_status)="item">
					<span v-html="getStatus(item)"></span>
				</template>				
				<template v-slot:head(total)="item">
					<div class="text-right pr-1">Amount</div>
				</template>				
				<template v-slot:cell(total)="item">
					<div class="text-right pr-1" :class="getActionClass(item)">{{ formatAmount(item) }}</div>
				</template>				
				<template v-slot:cell(action)="item">
					<div class="d-flex justify-content-center" v-if="isRunning(item.index)">
						<i class="fad fa-spinner" :class="isRunning(item.index) ? 'fa-spin' : ''"></i>
					</div>
					<div v-else>
						<select class="form-control form-control-sm" @change="doAction(item, $event)">
							<option v-for="action in getActionOptions(item.index)" :value="action.value">{{ action.text }}</option>
						</select>
					</div>
				</template>				
    		</b-table>
	    </div>
    	<paginator :dataSet="dataSet" @changed="fetchInvoices"></paginator>
    </div>
</template>

<script>

import paginator from './Paginator';
var moment = require('moment');

export default {

	components: {paginator},

	data() {
		return {
			filter: '',
			styleFilter: 'unpaid',
			currentPage: 1,
			actionValue: 0,
			runIndex: -1,
			dataSet: false,
			items: [],
            moment : moment,
            fields: [
            	{
            		key: 'created_at',
        			label: 'Date',
            	},
            	{
            		key: 'invnum',
        			label: 'Invoice #',
            	},
            	{
            		key: 'customer.company',
        			label: 'Recipient',
            	},
            	{
            		key: 'show_status',
        			label: 'Status',
            	},
            	{
            		key: 'total',
        			label: 'Amount',
            	},
            	{
            		key: 'action',
        			label: 'Action',
            	}
        	],
        	selectedRow: []
		}
	},

	mounted() {
		this.fetchInvoices();

        window.events.$on("reload", data => {
        	this.fetchInvoices();
        });
	},

	computed: {
		styleUnpaid() {
			return (this.styleFilter == 'unpaid') ? "bg-grey" : "bg-grey-lighter";
		},
		stylePaid() {
			return (this.styleFilter == 'paid') ? "bg-grey" : "bg-grey-lighter";
		}
	},

	watch: {
		filter: function(val) {
			if (val.length > 1) {
				this.filter = val;
				this.fetchInvoices(this.currentPage);
			}
		}
	},

	methods: {
		fetchInvoices(page = 1) {
			this.currentPage = page;
			let url = "/invoice?page=" + page;
			url += (this.styleFilter == '') ? '' : "&style=" + this.styleFilter
			url += "&filter=" + this.filter;
			axios.get(url)
				.then(response => {
					this.refresh(response.data);
				})
				.catch(error => {
					flash(error.response.data);
				});
		},

		refresh(data) {
			this.dataSet = data;
			this.items = data.data;

		},

		setStyleFilter(newstyle) {
			this.styleFilter = (this.styleFilter == newstyle) ? '' : newstyle;
			this.fetchInvoices((this.currentPage == undefined) ? 1 : this.currentPage);
		},

		// Return the item line status text - sent date, licensed, etc
		getStatus(value) {
			let result = "";
			switch (value.item.action) {
				case 0: result = "<span class='text-red'>Not Sent</span>"; break;
				case 1: 
					if (value.item.last_reminder == undefined) {
						result = "<span class='text-grey-dark'>Sent: " + moment(value.item.sent).format('DD-MMM-YYYY')+"</span>"; 
					} else {
						result = "<span class='text-grey-dark'>Reminder: " + moment(value.item.last_reminder).format('DD-MMM-YYYY')+"</span>"; 
					}
					break;
				case 3: return "<span class='text-grey'>Paid " + moment(value.item.completed).format('DD-MMM-YYYY')+"</span>"; break;
				case 4: return "<span class='text-grey'>Void " + moment(value.item.completed).format('DD-MMM-YYYY')+"</span>"; break;
			}
			let dueby = moment(value.item.due_by);
			let diff = moment.duration(moment().diff(dueby));
			
			let datestyle = "text-green";
			if (diff._data.days > -1) {
				datestyle = "text-red";
			}
			result = result + "<div class='" + datestyle + "'>" + ((diff._milliseconds > 0) ? "Overdue by " : "Due in ") + diff.humanize()+"</div>";
			if (value.item.delta_license_id != 0) {
				result = result + "<div class='text-grey'>License created</div>";
			}
			return result;
		},

		// return the class css for the row - mainly for unpaid, void and paid
		getActionClass(item) {
			if (item.hasOwnProperty('item')) {
				switch (item.item.action) {
					case 3: return 'text-grey-dark';
					case 4: return 'text-red-light';
					default:
					   return 'text-grey-darkest';
				}
			} else {
				return 'text-grey-darker';
			}
		},

		// create a new invoice
		doNewInvoice() {
            this.$emit("form-action", {
                action: 'customer',
                form: {}
            });
		},

		// reset the invoice list filter
		clearFilter() {
			this.filter = '';
			this.fetchInvoices(1);
		},

		// return a list of actions allowed for this item 
		getActionOptions(index) {
			// was thinking that status could be a page that shows the invoice details that allows actions where preview does not 
			// The text is the key/value so don't change the text within changing above doAction
			var textvalue = ['Choose..','Preview', 'Email Invoice','Payment','Status','Make Order','Make Renewal','Edit'];
			let invoice = this.items[index];
			if (! _.isEmpty(invoice.last_reminder)) {
				textvalue.push('Reminder');
			}
			if (invoice.action > 2) {
				return this.getPaidActionOptions(index);
			}
			let result = [];
			let i = -1;
			while (++i < textvalue.length) {
				if (textvalue[i] == 'Make Order' && ! this.isSQLDeltaOrderable(invoice.invoice_lines)) {
					continue;
				}
				if (textvalue[i] == 'Make Renewal' && ! this.isSQLDeltaRenewable(invoice.invoice_lines)) {
					continue;
				}
				result.push({value:textvalue[i], text: textvalue[i] });
			}
        	return result;
		},

		// A paid row has different available actions to unpaid row
		getPaidActionOptions(index) {
			const textvalue = ['Choose..','Status', 'Preview', 'Make Order','Make Renewal'];
			let invoice = this.items[index];
			let result = [];
			let i = -1;
			while (++i < textvalue.length) {
				result.push({value:textvalue[i], text: textvalue[i] });
			}
        	return result;
		},

		// Main response to drop down actions
		doAction(item, event) {
			let invoice = this.items[item.index];
			switch(event.target.value) {
				case 'Choose..': break;
				case 'Preview': this.doPreview(invoice.invoice_slug); break;
				case 'Email Invoice': this.sendEmail(invoice, item.index); break;
				case 'Make Order': this.makeOrder(invoice, item.index); break;
				case 'Make Renewal': this.makeRenewal(invoice, item.index); break;
				case 'Status': this.doStatus(invoice, item.index); break;
				case 'Edit': this.doEdit(invoice, item.index); break;
				case 'Payment': this.doPayment(invoice, item.index, "pay"); break;
				case 'Reminder': this.sendReminder(invoice, item.index); break;
				// case 'Review': this.doReview(invoice.invoice_slug); break;
			}
			event.target.value = "Choose..";
		},

		doRowSelect(items) {
			if (! Array.isArray(items) || items.length == 0) {
				if (typeof this.selectedRow !== 'undefined') {
					this.doStatus(this.selectedRow, this.selectedRow.index); 
				}
				return;
			}
			this.selectedRow = items[0];
			this.doStatus(items[0], items[0].index); 
		},

		// Show a PDF invoice - as a preview before emailing
		doPreview(invoice_id) {
			window.open("invoice/print/" + invoice_id, '_blank');
		},

		// this endpoint has been commented out so this no longer works and can be removed in future
		doReview(invoice_id) {
			window.open("invoice/review/" + invoice_id, '_blank');
		},

		// Create a new license (called order in SQL Delta) if the product type is SQL Delta/DXP product
		makeOrder(invoice, index) {
			if (invoice.reseller_id != 0) {
				axios.post('/reseller/get', {reseller_id: invoice.reseller_id})
					.then(response => {
						this.makeOrderPart2(invoice, response.data);
					});
			} else {
				this.makeOrderPart2(invoice, null);
			}
		},

		makeOrderPart2(invoice, reseller) {
            this.$emit("form-action", {
                action: 'deltauser',
                form: invoice,
                reseller: reseller
            });
		},

		// Create a new license (called order in SQL Delta) if the product type is SQL Delta/DXP product
		makeRenewal(invoice, index) {
			if (invoice.reseller_id != 0) {
				axios.post('/reseller/get', {reseller_id: invoice.reseller_id})
					.then(response => {
						this.makeRenewalPart2(invoice, response.data);
					});
			} else {
				this.makeRenewalPart2(invoice, null);
			}
		},

		makeRenewalPart2(invoice, reseller) {
            this.$emit("form-action", {
                action: 'deltasub',
                form: invoice,
                reseller: reseller
            });
		},

		// Edit the invoice
		doEdit(invoice, index) {
			if (invoice.reseller_id != 0) {
				axios.post('/reseller/get', {reseller_id: invoice.reseller_id})
					.then(response => {
						this.doEditPart2(invoice, response.data);
					});
			} else {
				this.doEditPart2(invoice, null);
			}
		},

		doEditPart2(invoice, reseller) {
            this.$emit("form-action", {
                action: 'editinvoice',
                form: invoice,
                reseller: reseller
            });
		},

		// Show the invoice in a status layout - as opposed to as pdf preview
		doStatus(invoice, index) {
            this.$emit("form-action", {
                action: 'invstatus',
                form: invoice,
                reseller: {}
            });
		},

		// Pay or Void the invoice
		doPayment(invoice, index, action) {
            this.$emit("form-action", {
                action: 'payment',
                form: invoice,
                reseller: {},
                mode: action
            });
		},

		// Email the invoice to the customer
		sendEmail(invoice, index) {
			if (invoice.action > 0) {
				if (confirm("You have already sent the invoice, resend anyway")) {
					this.doSendEmail(invoice, index);
				}
			} else {
				this.doSendEmail(invoice, index);
			}
		},

		doSendEmail(invoice, index) {
			this.runIndex = index;
			axios.get('/invoice/send/'+invoice.invoice_slug)
				.then(response => {
					this.runIndex = -1
					invoice.action = 1;
					invoice.sent = moment();
					flash(response.data);
				})
				.catch(error => {
					this.runIndex = -1;
					flash("Sorry, Something went wrong");
				});
		},

		// Forcibly email a reminder to customer - reminders are automated
		sendReminder(invoice, index) {
			if (! _.isEmpty(invoice.last_reminder)) {
				if (! confirm("You have already sent the reminder, resend anyway")) {
					return;
				}
			}
			this.doSendReminder(invoice, index);
		},

		// the actual send reminder email request
		doSendReminder(invoice, index) {
			this.runIndex = index;
			axios.get('/invoice/remind/'+invoice.invoice_slug+"?action=force")
				.then(response => {
					this.runIndex = -1
					invoice.action = 1;
					invoice.sent = moment();
					flash(response.data);
				})
				.catch(error => {
					this.runIndex = -1;
					flash("Sorry, Something went wrong");
				});
		},

		isSQLDeltaOrderable(lines) {
			const deltaItems = ['SDSS', 'SDMY', 'SDO', 'SDSS-S', 'SDMY-S', 'SDO-S', 'DXPSM', 'DXPOM', 'DXPPM', 'DXPSM-S', 'DXPOM-S', 'DXPPM-S'];
			for(var i = 0; i < lines.length; i++) {
				if (deltaItems.indexOf(lines[i].code) > -1 && ! lines[i].license_id) return true;
			}
			return false;
		},

		isSQLDeltaRenewable(lines) {
			const deltaItems = ['SDSS-R', 'SDMY-R', 'SDO-R', 'SDSS-RC', 'SDMY-RC', 'SDO-RC', 'DXPSM-R', 'DXPOM-R', 'DXPPM-R', 'DXPSM-RC', 'DXPOM-RC', 'DXPPM-RC',
								'SDSS-SR', 'SDMY-SR', 'SDO-SR', 'SDSS-SRC', 'SDMY-SRC', 'SDO-SRC', 'DXPSM-SR', 'DXPOM-SR', 'DXPPM-SR', 'DXPSM-SRC', 'DXPOM-SRC', 'DXPPM-SRC'];
			for(var i = 0; i < lines.length; i++) {
				if (deltaItems.indexOf(lines[i].code) > -1 && _.isInteger(lines[i].license_id)) return true;
			}
			return false;
		},

		// is the current line item doing something
		isRunning(index) {
			return index == this.runIndex;
		},

		// return description of invoice line items
		getInvoiceItems(item) {
			let result = "";
			item.item.invoice_lines.forEach(line => {
				result += (result != '') ? "<br>" : "";
				result += line.line_desc;
			});
			result += "<br>" + item.item.email;
			return result;
		},

		formatAmount(value) {
			let curr = '';
			if (this.items[value.index].currency == 0) {
				curr = 'US';
			}
			return this.getFormattedDecimal(value.value);
		},

        /**
        * Display the value as a currency $9,999.99 value
        */
        getFormattedDecimal(value, curr = '') {
            const formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2
            });
            return formatter.format(value);
        },
	}

}
</script>