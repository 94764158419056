<template>
    <div class="col-12 col-md-10 col-lg-8">
        <div class="bg-green-lightest border-1 border-green-dark p-3 mb-3" v-if="hasLicense">
            <div>Your order was completed on {{ moment(license.created_at).format('DD-MMM-YYYY') }}</div>
            <div>For {{ license.quantity }}  of {{ license.productdesc }}</div>
            <div>{{ license.regcode }}</div>
        </div>
        <form method="post" autocomplete="off" @keydown="form.clearErrors($event.target.name)">
            <div class="card">
                <div class="card-header">
                    <div class="" v-for="line in masterInvoice.invoice_lines">
                        <div class="col-12">
                            <span class="font-semibold">Adding a New SQL Delta User for: </span v-if="line.id == 1"> {{ line.line_desc }} x {{ line.qty }}
                        </div>
                    </div>
                    <div class="col-12" v-if="isReseller()">
                        For reseller: {{ masterReseller.company }}
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-row align-items-center">
                        <div class="col-12 ml-3 mb-3">
                            <input type="checkbox" class="form-check-input" id="usecompany" name="usecompany" v-model="useCompany">
                            <label for="usecompany" class="form-check-label">Use Invoice Customer as SQL Delta User</label>
                        </div>
                    </div>
                    <div class="form-row align-items-center">
                        <div class="col-2 col-1">
                         <label for="email">Email:</label>
                        </div>
                        <div class="col">
                            <div class="input-group">
                                <b-form-input
                                    type="search"
                                    id="email" 
                                    name="email"
                                    list="emailList" 
                                    @input="loadEmail"
                                    @change="doEmailComplete"
                                    @blur="doEmailComplete"
                                    v-model="form.email" 
                                    autocomplete="off"
                                    autofocus>
                                </b-form-input>
                                <div class="input-group-append" v-if="form.getLoading">
                                    <span class="btn btn-primary"><i class="fad fa-spinner " :class="form.getLoading ? 'fa-spin' : ''"></i></span>
                                </div>
                                <div class="input-group-append" v-else>
                                    <button class="btn btn-primary" 
                                            type="button" 
                                            v-b-tooltip="{placement: 'top', delay: {'show': 750, 'hide': 100}, variant:'info'}"
                                            role="tooltip"
                                            title="Click find and load existing contact or will clear if they dont exist"
                                            @click.prevent="forceEmailComplete(true)"
                                            id="button-addon2"><i class="fal fa-file-check"></i></button>
                                </div>
                            </div>
                                <datalist id="emailList">
                                    <option v-for="email in form.emailData">{{ email }}</option>
                                </datalist>                                    
                            <div class="text-grey-dark text-xs mt-2">New customer email or search for email or name of existing customer</div>
                            <span class="text-red text-sm" v-if="form.hasErrors('email')" v-text="form.errors.get('email')"></span>
                        </div>
                    </div>
                </div>
            </div>
            <contact :contact="form"></contact>
            <div class="col-12 bg-white border mt-2 rounded d-inline-flex justify-content-end">
                <div class="mr-2">
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded px-2" 
                        type="button" 
                        :disabled="form.anyErrors() || form.email == '' || hasLicense"
                        @click.prevent="doSave"><i class="fal fa-save mr-2"></i>Save</button>
                </div>
                <div class="">
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                        type="button" 
                        @click.prevent="doCancel"><i class="fal fa-arrow-alt-right mr-2"></i>{{ finText }}</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

import ContactForm from './contact/contactform.js';
import Countries from './contact/countries.js';
import contact from './contact/Contact'
import _ from 'lodash'

var moment = require('moment');

export default {
    components: {
        contact
    },

    data() {
        return {
            masterInvoice: {},
            masterReseller: {},
            useCompany: false,            
            moment: moment,
            form: new ContactForm({
                email:'',
                company: '',
                firstname: '',
                lastname: '',
                password:'',
                confirmpassword: '',
                is_company: true,
                is_reseller: false,
                tax: 0,
                currency: 0,
                addr1 : '',
                addr2 : '',
                suburb: '',
                countrydata: new Countries({
                    country: '',
                    countrycode: '',
                    state: '',
                    statecode: '',
                    postcode: '',
                    vat:''
                }),
                customer_id: 0,
                qty: 0,
                productcode: '',
                license_id: 0,
                reseller_id: 0,
                ponumber: '',
                billemail: '',
                invnum: '',
                unit: 0,
                discount: 0
            }, {
                showVat: false,
                showPassword: false,
                showIsCompany: true,
                showCurrency: false,
                showIsReseller: false,
                showHasTax: false,
                title: 'SQL Delta Registered User'
            }),
            customerSaved: false,
            loadedUser: {},
            license: {},
            hasLicense: false,
            finText: 'Cancel'
        }
    },

    mounted() {
        window.events.$on("order_reload", data => {
            this.doReload();
        });
        this.doReload();

    },

    watch: {
        useCompany: function(val) {
            this.updateContact(val);
        }

    },

    methods: {
        doReload() {
            this.license = {};
            this.hasLicense = false;
            this.masterInvoice = {};
            this.masterReseller = {};
            if (! _.isEmpty(this.$parent.masterInvoice)) {
                this.masterInvoice = this.$parent.masterInvoice;
                this.setFormData(this.masterInvoice.hasOwnProperty('invLines') ? this.masterInvoice.invLines[0] : this.masterInvoice.invoice_lines[0]);
            }
            if (! _.isEmpty(this.$parent.masterReseller)) {
                this.masterReseller = this.$parent.masterReseller;
            }
            this.loadOrder();
        },

        setFormData(line1) {
            this.form.qty = line1.qty;
            this.form.productcode = line1.code;
            this.form.license_id = line1.license_id;
            this.form.unit = line1.unit;
            this.form.reseller_id = this.masterInvoice.reseller_id;
            this.form.ponumber = this.masterInvoice.reference;
            this.form.billemail = this.masterInvoice.customer.email;
            this.form.invnum = this.masterInvoice.invnum;
        },

        loadOrder() {
            axios.post('order/getinvoice', {invnum: this.form.invnum})
                .then(response => {
                    this.license = response.data.license;
                    this.hasLicense =  (this.license != undefined && Object.keys(this.license).length != 0);
                    this.form.foundItem = this.hasLicense;
                    this.finText = (this.hasLicense) ? 'Close' : 'Cancel';
                });
        },

        // suggest from server email(s) based on input value 
        loadEmail(value) {
            this.form.setFoundStatus(false);
            this.form.listByEmail(value, '/order/finduser');
        },

        // either a new email or load the details ready for updating the customer
        emailComplete(force) {
            if (force || ! this.form.getFoundStatus()) {
                this.form.get('/order/getuser')
                    .then(data => {
                        if (! _.isEmpty(data) || force) {
                            this.loadedUser = data;
                            this.form.copyData(data.company);
                            this.form.firstname = data.firstname;
                            this.form.lastname = data.lastname;
                            this.form.company = data.company.name;
                            this.form.customer_id = data.id;
                        }
                    });      //.then - all handled withn get && .catch -  Errors in retrieve dont matter 
            }
        },

        isReseller() {
            return (this.masterReseller && this.masterReseller.hasOwnProperty("company") && this.masterReseller.company != '');
        },

        forceEmailComplete() {
            this.emailComplete(true);
        },

        doEmailComplete() {
            this.emailComplete(false);
        },

        notOkForNext() {
            return ! this.form.getFoundStatus() && (this.form.email == '' || this.form.countrydata.country === '' && this.form.suburb === '');
        },

        // Just save/update the customer details
        doSave() {
            this.save(false);
        },

        // Move to the next page, saving if new customer
        doCancel() {
            this.$emit("form-action", {
                action: 'invoice-list'
            });
        },

        save(toReseller = false) {
            // this.setFormData(this.form);
            this.form.post('/order/store')
                .then(data => {
                    flash(data.message);
                    this.finText = 'Close'
                    this.loadOrder();
                }).catch(errors => {
                });
        },


        updateContact(docopy) {
            if (docopy) {
                this.form.copyData(this.masterInvoice.customer);
            } else {
                this.form.reset();
            }
        }

    }
}
</script>
