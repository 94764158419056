<template>
    <div class="col-12 col-md-10 col-lg-8">
        <form method="post" autocomplete="off" @submit.prevent="doNext" @keydown="form.clearErrors($event.target.name)">
            <div class="card">
                <div class="card-header">Reseller Details</div>
                <div class="card-body">
                    <div class="form-row align-items-center justify-content-center mt-1 mb-3">
                        <div class="px-3 custom-control custom-switch" style="min-width: 85px;">
                            <input type="checkbox" class="custom-control-input" id="wantreseller" name="wantreseller" v-model="wantReseller" @change="updateWantReseller">
                            <label for="wantreseller" class="custom-control-label">Separate Reseller required</label>
                            <span class="text-red text-sm" v-if="form.hasErrors('wantReseller')" v-text="form.errors.get('wantReseller')"></span>
                        </div>
                    </div>

                    <div class="form-row align-items-center" v-if="wantReseller">
                        <div class="col-2">
                         <label for="email">Email:</label>
                        </div>
                        <div class="col-10 col-sm-8">
                            <b-form-input
                                type="search"
                                id="email" 
                                name="email"
                                list="emailList" 
                                @input="loadEmail"
                                @change="emailComplete"
                                @blur="onBlurEmail"
                                v-model="form.email" 
                                autocomplete="off"
                                autofocus>
                            </b-form-input>
                            <datalist id="emailList">
                                <option v-for="email in form.emailData">{{ email }}</option>
                            </datalist>                                    
                            <div class="text-grey-dark text-xs my-2">Reseller details can be pushed to SQL Delta if required. Search from SQL Delta data or copy from buyer</div>
                            <div class="text-grey-darker text-xs my-2 align-items-center" v-if="form.generalmsg != ''"><i class="fal fa-exclamation-square mt-1 mr-2 text-orange-dark text-lg"></i>{{ form.generalmsg }}</div>
                            <span class="text-red text-sm" v-if="form.hasErrors('email')" v-text="form.errors.get('email')"></span>
                        </div>
                    </div>
                    <div class="form-row align-items-center justify-content-end mt-2" v-if="wantReseller">
                        <div class="px-3" style="">
                            <button 
                                class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                                id="copybuyer" 
                                name="copybuyer" 
                                @click.prevent="updateCopyBuyer"><i class="fal fa-copy mr-2"></i>Copy Buyer Detail</button>
                        </div>
                        <div class="px-3" style="">
                            <a href="#"
                                class="my-2 px-2 py-1  text-red" 
                                id="resetform" 
                                name="resetform" 
                                @click.prevent="doResetForm"><i class="fal fa-times-circle mr-2"></i>Clear</a>
                        </div>

                    </div>
                </div>
            </div>
            <contact :contact="form" v-if="wantReseller"></contact>
            <div class="col-12 bg-white border mt-2 rounded d-inline-flex justify-content-end" v-if="! form.getFoundStatus()">
                <div class="mr-4">
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                        type="button" 
                        @click.prevent="doChangePage('customer')"><i class="fal fa-arrow-alt-left mr-2"></i>Back</button>
                </div>
                <div class="mr-2">
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded px-2" 
                        type="submit" 
                        :disabled="form.anyErrors()"
                        v-if="wantReseller"
                        @submit.prevent="doSave"><i class="fal fa-save mr-2"></i>Save</button>
                </div>
                <div class="">
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                        type="button" 
                        :disabled="form.anyErrors() || notOkForNext()"
                        @click.prevent="doChangePage('invoice')"><i class="fal fa-arrow-alt-right mr-2"></i>Next</button>
                </div>
            </div>
            <div class="col-12 bg-white border mt-2 rounded d-inline-flex justify-content-end" v-else>
                <div class="mr-4">
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                        type="button" 
                        @click.prevent="doChangePage('customer')"><i class="fal fa-arrow-alt-left mr-2"></i>Back</button>
                </div>
                <div class="mr-2">
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                        type="button" 
                        :disabled="form.anyErrors()"
                        v-if="wantReseller"
                        @click.prevent="doSave"><i class="fal fa-save mr-2"></i>Update</button>
                </div>
                <div class="">
                    <button 
                        class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                        type="submit" 
                        :disabled="form.anyErrors() || notOkForNext()"
                        @submit.prevent="doChangePage('invoice')"><i class="fal fa-arrow-alt-right mr-2"></i>Next</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

import ContactForm from './contact/contactform.js';
import Countries from './contact/countries.js';
import contact from './contact/Contact'
import _ from 'lodash'

export default {
    components: {
        contact
    },

    data() {
        return {
            wantReseller: false,
            form: new ContactForm({
                email:'',
                company: '',
                firstname: '',
                lastname: '',
                password:'',
                confirmpassword: '',
                is_company: true,
                is_reseller: true,
                tax: 0,
                currency: 0,
                addr1 : '',
                addr2 : '',
                suburb: '',
                countrydata: new Countries({
                    country: '',
                    countrycode: '',
                    state: '',
                    statecode: '',
                    postcode: '',
                    vat:''
                }),
                reseller_id: 0
            }, {
                showVat: false,
                showPassword: false,
                showIsCompany: true,
                showCurrency: false,
                showIsReseller: false,
                showHasTax: false,
                title: 'Reseller name and address'
            }),
            customerSaved: false,
            masterForm: {}
        }
    },

    mounted() {
        window.events.$on("reseller_reload", data => {
            this.doReload();
        });
        this.doReload();
    },

    methods: {
        doReload() {
            if (! _.isEmpty(this.$parent.masterForm)) {
                this.masterForm = this.$parent.masterForm.extractData();
                // if the buyer is a reseller then add them into SQL Delta data (or update them)
                if (this.masterForm.is_reseller) {
                    this.form.email = this.masterForm.email;
                    this.wantReseller = true;
                    // load from SQL Delta their info if it exists
                    this.emailComplete();
                }
            }
        },

        // suggest from server email(s) based on input value 
        loadEmail(value) {
            if (! this.wantReseller) return;
            this.form.generalmsg = '';
            this.form.reseller_id = 0;
            this.form.setFoundStatus(false);
            this.form.listByEmail(value, '/reseller/find');
        },

        // either a new email or load the details ready for updating the customer
        emailComplete() {
            if (! this.wantReseller || this.form.email == '') return;
            this.form.get('/reseller/get')
                .then(data => {
                    if (! _.isEmpty(data) && this.form.getFoundStatus()) {
                        this.form.generalmsg = '';
                        this.fillFoundContact(data);
                    } else {
                        if (this.form.email != '') {
                            this.form.generalmsg = "Reseller email address does not exist";
                        }
                    }
                });
        },

        // it's possible to autofill and not generate a change event so this ensures a load occurs if needed and ignore if already done
        onBlurEmail() {
            if (! this.wantReseller) return;

            if (! this.form.getFoundStatus()) {
                this.emailComplete();
            }
        },

        updateWantReseller() {
            if (this.wantReseller) {
                this.form.email = this.masterForm.email;
            } else {
                this.form.reset();
            }
        },

        // Take the parent form data and copy into current form
        updateCopyBuyer() {
            if (! _.isEmpty(this.masterForm)) {
                if (this.form.email == '') {
                    this.form.email = this.masterForm.email;
                }
                this.fillFoundContact(this.masterForm);
            } 
        },

        doResetForm() {
            this.form.reset();
        },

        // because the form data is somewhat dynamic it must be updated in the the vue and not form object
        fillFoundContact(data) {
            this.form.copyData(data, true, ['email']);
            this.form.reseller_id = data.reseller_id;
        },

        notOkForNext() {
            if (this.wantReseller && (this.form.email == '' || (! this.form.getFoundStatus() && ! this.customerSaved))) {
                return true;
            }
            return this.wantReseller && ! this.form.getFoundStatus() && (this.form.countrydata.country === '' && this.form.suburb === '');
        },

        // Just save/update the customer details
        doSave() {
            this.save();
        },

        save(changePage = '') {
            if (! this.wantReseller) {
                    if (changePage !== '') {
                        this.gotoNextPage(changePage);
                    }
                return;
            }

            let action = this.form.getFoundStatus() ? "put" : "post";
            this.form[action]('/reseller')
                .then(data => {
                    flash(data.message);
                    this.form.reseller_id = data.reseller_id;
                    this.customerSaved = true;
                    if (changePage !== '') {
                        this.gotoNextPage(changePage);
                    }
                }).catch(errors => {
                });
        },

        // Move to the next page, saving if new customer
        doNext() {
            if (! this.customerSaved && ! this.form.getFoundStatus()) {
                this.save(true);
            } else {
                this.gotoNextPage('invoice');
            }
        },

        // Move to the next page, saving if new customer
        doChangePage(page) {
            if (! this.customerSaved && ! this.form.getFoundStatus()) {
                this.save(page);
            } else {
                this.gotoNextPage(page);
            }
        },

        gotoNextPage(page) {
            this.$emit("form-action", {
                action: page,
                form: this.form
            });
        }

    }
}

</script>
