<template>
    <div class="">
        <div class="card mt-2">
            <div v-if="form.showTitle()" class="card-header" v-text="form.options.title"></div>
            <div class="card-body">
                <div class="form-row align-items-center">
                    <div class="col-12">
                        <label for="company" class="form-label">Company:</label>
                        <span class="float-right" v-if="form.showIsCompany()">
                            <input type="checkbox" tabindex=2 class="form-check-input" id="iscompany" name="iscompany" v-model="form.is_company">
                            <label for="iscompany" class="form-check-label">Is Company</label>
                        </span>
                        <input type="text" tabindex=3 id="company" name="company" class="form-control" v-model="form.company" autocomplete="off">
                        <span class="text-red text-sm" v-if="form.hasErrors('company')" v-text="form.errors.get('company')"></span>
                    </div>
                </div>
                <div class="form-row align-items-center mt-2">
                    <div class="col-6">
                        <label for="firstname" class="form-label">First Name:</label>
                        <input type="text" tabindex=4 id="firstname" name="firstname" class="form-control" v-model="form.firstname">
                        <span class="text-red text-sm" v-if="form.hasErrors('firstname')" v-text="form.errors.get('firstname')"></span>
                    </div>
                    <div class="col-6">
                        <label for="lastname" class="form-label">Last Name:</label>
                        <input type="text" tabindex=5 id="lastname" name="lastname" class="form-control" v-model="form.lastname">
                        <span class="text-red text-sm" v-if="form.hasErrors('last')" v-text="form.errors.get('lastname')"></span>
                    </div>
                </div>
                <div class="form-row align-items-center mt-2" v-if="form.showPassword()">
                    <div class="col-6">
                        <label for="password" class="form-label">Password:</label>
                        <input type="text" tabindex=6 id="password" name="password" class="form-control" v-model="form.password">
                        <span class="text-red text-sm" v-if="form.hasErrors('password')" v-text="form.errors.get('password')"></span>
                    </div>
                    <div class="col-6">
                        <label for="confirmpassword" class="form-label">Confirm Password:</label>
                        <input type="text" tabindex=7 id="confirmpassword" name="confirmpassword" class="form-control" v-model="form.confirmpassword">
                        <span class="text-red text-sm" v-if="form.hasErrors('confirmpassword')" v-text="form.errors.get('confirmpassword')"></span>
                    </div>
                </div>
                <div class="form-row align-items-center mt-2">
                    <div class="col-12">
                        <label for="addr1" class="form-label">Address Line 1:</label>
                        <input type="text" tabindex=8 id="addr1" name="addr1" class="form-control" v-model="form.addr1">
                        <span class="text-red text-sm" v-if="form.hasErrors('addr1')" v-text="form.errors.get('addr1')"></span>
                    </div>
                </div>
                <div class="form-row align-items-center mt-2">
                    <div class="col-12">
                        <label for="addr2" class="form-label">Address Line 2:</label>
                        <input type="text" tabindex=9 id="addr2" name="addr2" class="form-control" v-model="form.addr2">
                        <span class="text-red text-sm" v-if="form.hasErrors('addr2')" v-text="form.errors.get('addr2')"></span>
                    </div>
                </div>
                <div class="form-row align-items-center mt-2">
                    <div class="col-12">
                        <label for="suburb" class="form-label">Suburb:</label>
                        <input type="text" tabindex="10" id="suburb" name="suburb" class="form-control" v-model="form.suburb">
                        <span class="text-red text-sm" v-if="form.hasErrors('suburb')" v-text="form.errors.get('suburb')"></span>
                    </div>
                </div>
                <country :form="form" :displayVat="form.showVAT()"></country>
                <div :class="extrasClass" v-if="showExtras">
                    <div class="d-flex flex-wrap px-3" v-if="form.options.showHasTax">
                        <select class="custom-select" tabindex=16 v-model="form.tax">
                            <option v-for="taxOption in taxOptions()" :value="taxOption.value" :selected="taxOption.selected">{{ taxOption.text }}</option>
                        </select>
                        <span class="text-red text-sm" v-if="form.hasErrors('tax')" v-text="form.errors.get('tax')"></span>
                    </div>
                    <div class="d-flex flex-wrap px-3" v-if="form.options.showCurrency">
                        <select class="custom-select" tabindex=17 v-model="form.currency">
                            <option v-for="currencyOption in currencyOptions()" :value="currencyOption.value" :selected="currencyOption.selected">{{ currencyOption.text }}</option>
                        </select>
                        <span class="text-red text-sm" v-if="form.hasErrors('currency')" v-text="form.errors.get('currency')"></span>
                    </div>
                    <div class="px-3 custom-control custom-switch" style="min-width: 85px; left:40px;" v-if="form.options.showIsReseller">
                        <input type="checkbox" tabindex=18 class="custom-control-input" id="isreseller" name="isreseller" v-model="form.is_reseller">
                        <label for="isreseller" class="custom-control-label">Is Reseller</label>
                        <span class="text-red text-sm" v-if="form.hasErrors('isreseller')" v-text="form.errors.get('isreseller')"></span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import country from './Country.vue';
import ContactForm from './contactform.js';

export default {
    
    props: ['contact'],
    
    components: {
        country
    },

    data() {
        return {
            form: this.contact
        }
    },

    computed: {
        
        extrasClass() {
            return this.showExtras() ? "row align-items-center mt-4 py-3 border-t border-grey" : "";
        }
    },

    methods: {
        showExtras() {
            return (this.form.options.showCurrency || this.form.options.showHasTax || this.form.options.showIsReseller);
        },

        // There are currently so few tax options that hard coding is fine.
        // however there is support for large scale tax handiling if needed
        taxOptions() {
            return {
                0: {text:"No Tax", value: 0, selected: (this.form.tax == 0)},
                1: {text: "10% GST", value: 10, selected: (this.form.tax == 10)}
            }
        },

        // There are currently 2 currency options that hard coding is fine.
        // however there is support for large scale currency handiling if needed
        currencyOptions() {
            return {
                0: {text:"USD", value: 0, selected: (this.form.currency == 0)},
                1: {text: "AUD", value: 1, selected: (this.form.currency == 1)}
            }
        }
    }
}

</script>
