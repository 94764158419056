<template>
<div class="container-fluid text-grey-light">
	<div class="row justify-content-center text-grey-darker" style="margin-top: 5%">
		<div class="col-12 col-md-6">
			<form id="payment-form">
				<div class="card">
					<div class="card-header">
						<div class="row">
							<div class="col-7">
								<div>Invoice To:</div>
								<div>{{invoice.customer.company}}</div>
								<div>{{invoice.customer.addr1}}</div>
								<div>{{invoice.customer.addr2}}</div>
								<div>{{invoice.customer.suburb}} {{invoice.customer.state}} {{invoice.customer.postcode}}</div>
								<div>{{invoice.customer.country}}</div>
							</div>
							<div class="col-5 text-right">
								<div>Invoice #:<span class="ml-2">{{ invoice.invnum }}</span></div>
								<div>Reference:<span class="font-bold ml-2">{{ invoice.reference }}</span></div>
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="row px-3 pb-3 mb-3 border-b border-grey-light">
							<div class="col-12">
								<div class="row" v-for="line in invoice.invoice_lines">
									<div class="col-12">{{ line.line_desc }}</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-6">Invoice total </div>
							<div class="col-6 text-right">{{ number_format(invoice.lines_total,2,'.',',') }}</div>
						</div>
						<div class="row">
							<div class="col-6">Discount</div>
							<div class="col-6 text-right">{{ number_format(invoice.discount_value,2,'.',',') }}</div>
						</div>
						<div class="row">
							<div class="col-6">Tax</div>
							<div class="col-6 text-right">{{ number_format(invoice.tax_value,2,'.',',') }}</div>
						</div>
						<div class="row font-bold">
							<div class="col-6">Amount Due</div>
							<div class="col-6 text-right">{{invoice.currency == 0 ? "US$ " : "AU$ "}}{{ number_format(invoice.total,2,'.',',') }}</div>
						</div>
						<div class="row mt-3 border-t border-grey-light">
							<div class="col-12 mt-2">
								<label class="form-label" for="card-element">Card details</label>
						        <div class="">
						        	<div class="border border-grey-light p-2 rounded" id="card-element" name='card-element' ref="card"></div>
						        </div>
								<div class="text-red text-sm mt-1" id="card-errors" role="alert" v-if="error !== ''">
									<i class="far fa-exclamation-circle mr-2"></i><span class="">{{ error }}</span>
								</div>
						    </div>
					    </div>
						<div class="row">
							<div class="col-12">
								<label class="form-label mt-2" for="receipt">Receipt Email</label>
								<input class="form-control" id="receipt" name="receipt" type="text" placeholder="Receipt email" v-model="receipt">
							</div>
						</div>
					</div>
					<div class="card-footer">
						<div class="row  py-2">
							<div class="col-12 d-flex justify-content-center">
								<button class="btn btn-success" id="submit" @click.prevent="doPay" :disabled="submitting">
									<i class="far fa-spinner " :class="submitting ? 'fa-spin' : 'invisible'"></i>
					            	<span id="button-text" class="mr-2">Pay</span><span id="order-amount"> {{invoice.currency == 0 ? "US$ " : "AU$ "}}{{ number_format(invoice.total,2,'.',',') }}</span>
								</button>
							</div>
						</div>
						<div class="row" style="position: relative; top: 12px">
							<div class="col-6 text-xs text-grey-dark italic d-flex justify-content-start">
								<a class="text-grey-darker" href="https://tasc.com.au/" target="_blank">Invoiced by The Australian Software Company</a>
							</div>
							<div class="col-6 text-xs text-grey-dark italic d-flex justify-content-end">
								<a class="text-grey-darker" href="https://stripe.com/" target="_blank">Payment by Stripe</a>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
</template>


<script>

export default {
	props: ['invoice', 'intent'],

	data() {
		return {
			submitting: false,
			error: "",
			card: null,
			isPaid: false,
			receipt: ""
		}

	},

	mounted() {
		var self = this;
		this.receipt = this.invoice.customer.email;
		this.card = elements.create("card", { 
			hidePostalCode: true,
			style: {
			    base: {
			      iconColor: '#888888',
			      color: '#303238',
			      fontWeight: 400,
			      fontFamily: 'Nunito, Segoe UI, sans-serif',
			      fontSize: '16px',
			      fontSmoothing: 'antialiased',
			      ':-webkit-autofill': {
			        color: '#fce883',
			      },
			      '::placeholder': {
			        color: '#8795a1',
			      },
			    },
			    invalid: {
			      iconColor: '#FFC7EE',
			      color: '#E3342F',
			    },
			  },			
		 });
		this.card.mount(this.$refs.card);
		this.card.addEventListener('change', function (event) {
			self.error = '';
		});		
	},

	methods: {
		doPay() {
			let self = this;
			this.submitting = true;
			stripe.confirmCardPayment(this.intent, {
	  			receipt_email: this.receipt,
				payment_method: {
					card: self.card,
					billing_details: {
						email: this.invoice.customer.email
					},
				}
			}).then(result => {
				this.submitting = false;
				if (result.error) {
					self.hasCardErrors = true;
				    self.$forceUpdate(); 
				    self.error = result.error.message;
				    return;
				}
				window.location.assign('/pay-invoice/done');
			});
		},

		number_format(value, dec, sep) {
            const formatter = new Intl.NumberFormat('en-US', {
              minimumFractionDigits: dec
            });
            return formatter.format(value);
		}
	}
}	
</script>