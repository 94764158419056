<template>
	<div>
		<div class="form-row align-items-center mt-2">
			<div class="col-12">
				<label for="country" class="form-label">Country:</label>
			 	<v-select 
			 		class="style-select"
			 		:tabindex="11"
			 		id="country" 
			 		name="country" 
			 		:options="countries.countryList" 
			 		label="text" 
			 		:value="countries.country"
		 			:selectOnTab=true
		 			@input="setCountrySelected"></v-select>
	 			<span class="text-red text-sm" v-if="countries.errors.has('countrydata.country')" v-text="form.getErrors('country')"></span>
			 </div>
		</div>
		<div class="form-row align-items-center mt-2">
			<div class="col-8" v-if="hasStates">
				<label for="state" class="form-label">State:</label>
			 	<v-select 
			 		:tabindex="12"
			 		id="state" 
		 			name="state" 
		 			:options="countries.states"
		 			label="text"
		 			:value="countries.state"
		 			:selectOnTab=true
		 			@input="setStateSelected"></v-select>
	 			<span class="text-red text-sm" v-if="countries.errors.has('countrydata.state')" v-text="form.getErrors('state')"></span>
			 </div>
 			<div class="col-8" v-else>
				<label for="state" class="form-label">State:</label>
				<input type="text" tabindex=14 name="state" id="state" class="form-control" placeholder="Optional State or County" v-model="countries.state">
	 			<span class="text-red text-sm" v-if="countries.errors.has('countrydata.state')" v-text="form.getErrors('state')"></span>
			 </div>
			<div class="col-4" v-if="hasZip()">
				<label for="postcode" class="form-label">Zip/Postcode:</label>
			 	<input class="form-control" tabindex=15 type="text" id="postcode" name="postcode" v-model="countries.postcode">
	 			<span class="text-red text-sm" v-if="countries.errors.has('countrydata.postcode')" v-text="form.getErrors('postcode')"></span>
			 </div>
		</div>
		<div class="form-row align-items-center mt-2" v-if="needVat()">
			<div class="col-6">
				<label for="vat" class="form-label">VAT Number:</label>
			 	<input class="form-control" tabindex=16 type="text" id="vat_id" name="vat_id" v-model="countries.vat_id">
	 			<span class="text-red text-sm" v-if="countries.errors.has('countrydata.vat_id')" v-text="form.getErrors('vat_id')"></span>
			 </div>
		</div>
	</div>
</template>

<script>

import Countries from './countries.js';

export default {
	props: ['form', 'displayVat'],

    data() {
        return {
            countries: this.form.countrydata
        }
    },

    computed: {
		hasStates() {
			return (this.countries.states != undefined && this.countries.states.length > 0);
		}
    },

    methods: {
    	setCountrySelected(value) {
    		this.form.clearErrors("countrydata.country");
    		this.countries.setCountryValue(value);
    	},

    	setStateSelected(value) {
    		this.form.clearErrors('countrydata.state');
    		this.countries.setStateValue(value);
    	},

    	hasZip() {
    		return this.countries.hasZip();
    	},

    	needVat() {
    		return this.displayVat && this.countries.hasVat();
    	}
    }
}

</script>

<!-- <style>
.style-select.vs__selected-options {
	padding-top: 1px;
	padding-bottom: 1px;
}

.style-select.vs__search,
.style-select.vs__search:focus {
	line-height: 1.6;
}
.style-select.vs__open-indicator {
	cursor: pointer;
}
</style>
 -->