export const CountryList = [
			{ value:"AU", text:"Australia", disable: false },
			{ value:"CA", text:"Canada", disable: false },
			{ value:"CN", text:"China", disable: false },
			{ value:"FR", text:"France", disable: false },
			{ value:"DE", text:"Germany", disable: false },
			{ value:"IT", text:"Italy", disable: false },
			{ value:"GB", text:"United Kingdom", disable: false },
			{ value:"US", text:"United States", disable: false },
			{ value:"AF", text:"Afghanistan", disable: false },
			{ value:"AX", text:"Åland Islands", disable: false },
			{ value:"AL", text:"Albania", disable: false },
			{ value:"DZ", text:"Algeria", disable: false },
			{ value:"AS", text:"American Samoa", disable: false },
			{ value:"AD", text:"Andorra", disable: false },
			{ value:"AO", text:"Angola", disable: false },
			{ value:"AI", text:"Anguilla", disable: false },
			{ value:"AQ", text:"Antarctica", disable: false },
			{ value:"AG", text:"Antigua and Barbuda", disable: false },
			{ value:"AR", text:"Argentina", disable: false },
			{ value:"AM", text:"Armenia", disable: false },
			{ value:"AW", text:"Aruba", disable: false },
			{ value:"AT", text:"Austria", disable: false },
			{ value:"AZ", text:"Azerbaijan", disable: false },
			{ value:"BS", text:"Bahamas", disable: false },
			{ value:"BH", text:"Bahrain", disable: false },
			{ value:"BD", text:"Bangladesh", disable: false },
			{ value:"BB", text:"Barbados", disable: false },
			{ value:"BY", text:"Belarus", disable: false },
			{ value:"BE", text:"Belgium", disable: false },
			{ value:"BZ", text:"Belize", disable: false },
			{ value:"BJ", text:"Benin", disable: false },
			{ value:"BM", text:"Bermuda", disable: false },
			{ value:"BT", text:"Bhutan", disable: false },
			{ value:"BO", text:"Bolivia, Plurinational State of", disable: false },
			{ value:"BQ", text:"Bonaire, Sint Eustatius and Saba", disable: false },
			{ value:"BA", text:"Bosnia and Herzegovina", disable: false },
			{ value:"BW", text:"Botswana", disable: false },
			{ value:"BV", text:"Bouvet Island", disable: false },
			{ value:"BR", text:"Brazil", disable: false },
			{ value:"IO", text:"British Indian Ocean Territory", disable: false },
			{ value:"BN", text:"Brunei Darussalam", disable: false },
			{ value:"BG", text:"Bulgaria", disable: false },
			{ value:"BF", text:"Burkina Faso", disable: false },
			{ value:"BI", text:"Burundi", disable: false },
			{ value:"KH", text:"Cambodia", disable: false },
			{ value:"CM", text:"Cameroon", disable: false },
			{ value:"CV", text:"Cape Verde", disable: false },
			{ value:"KY", text:"Cayman Islands", disable: false },
			{ value:"CF", text:"Central African Republic", disable: false },
			{ value:"TD", text:"Chad", disable: false },
			{ value:"CL", text:"Chile", disable: false },
			{ value:"CX", text:"Christmas Island", disable: false },
			{ value:"CC", text:"Cocos (Keeling) Islands", disable: false },
			{ value:"CO", text:"Colombia", disable: false },
			{ value:"KM", text:"Comoros", disable: false },
			{ value:"CG", text:"Congo", disable: false },
			{ value:"CD", text:"Congo, the Democratic Republic of the", disable: false },
			{ value:"CK", text:"Cook Islands", disable: false },
			{ value:"CR", text:"Costa Rica", disable: false },
			{ value:"CI", text:"Côte d'Ivoire", disable: false },
			{ value:"HR", text:"Croatia", disable: false },
			{ value:"CU", text:"Cuba", disable: false },
			{ value:"CW", text:"Curaçao", disable: false },
			{ value:"CY", text:"Cyprus", disable: false },
			{ value:"CZ", text:"Czech Republic", disable: false },
			{ value:"DK", text:"Denmark", disable: false },
			{ value:"DJ", text:"Djibouti", disable: false },
			{ value:"DM", text:"Dominica", disable: false },
			{ value:"DO", text:"Dominican Republic", disable: false },
			{ value:"EC", text:"Ecuador", disable: false },
			{ value:"EG", text:"Egypt", disable: false },
			{ value:"SV", text:"El Salvador", disable: false },
			{ value:"GQ", text:"Equatorial Guinea", disable: false },
			{ value:"ER", text:"Eritrea", disable: false },
			{ value:"EE", text:"Estonia", disable: false },
			{ value:"ET", text:"Ethiopia", disable: false },
			{ value:"FK", text:"Falkland Islands (Malvinas)", disable: false },
			{ value:"FO", text:"Faroe Islands", disable: false },
			{ value:"FJ", text:"Fiji", disable: false },
			{ value:"FI", text:"Finland", disable: false },
			{ value:"GF", text:"French Guiana", disable: false },
			{ value:"PF", text:"French Polynesia", disable: false },
			{ value:"TF", text:"French Southern Territories", disable: false },
			{ value:"GA", text:"Gabon", disable: false },
			{ value:"GM", text:"Gambia", disable: false },
			{ value:"GE", text:"Georgia", disable: false },
			{ value:"GH", text:"Ghana", disable: false },
			{ value:"GI", text:"Gibraltar", disable: false },
			{ value:"GR", text:"Greece", disable: false },
			{ value:"GL", text:"Greenland", disable: false },
			{ value:"GD", text:"Grenada", disable: false },
			{ value:"GP", text:"Guadeloupe", disable: false },
			{ value:"GU", text:"Guam", disable: false },
			{ value:"GT", text:"Guatemala", disable: false },
			{ value:"GG", text:"Guernsey", disable: false },
			{ value:"GN", text:"Guinea", disable: false },
			{ value:"GW", text:"Guinea-Bissau", disable: false },
			{ value:"GY", text:"Guyana", disable: false },
			{ value:"HT", text:"Haiti", disable: false },
			{ value:"HM", text:"Heard Island and McDonald Islands", disable: false },
			{ value:"VA", text:"Holy See (Vatican City State)", disable: false },
			{ value:"HN", text:"Honduras", disable: false },
			{ value:"HK", text:"Hong Kong", disable: false },
			{ value:"HU", text:"Hungary", disable: false },
			{ value:"IS", text:"Iceland", disable: false },
			{ value:"IN", text:"India", disable: false },
			{ value:"ID", text:"Indonesia", disable: false },
			{ value:"IR", text:"Iran, Islamic Republic of", disable: false },
			{ value:"IQ", text:"Iraq", disable: false },
			{ value:"IE", text:"Ireland", disable: false },
			{ value:"IM", text:"Isle of Man", disable: false },
			{ value:"IL", text:"Israel", disable: false },
			{ value:"JM", text:"Jamaica", disable: false },
			{ value:"JP", text:"Japan", disable: false },
			{ value:"JE", text:"Jersey", disable: false },
			{ value:"JO", text:"Jordan", disable: false },
			{ value:"KZ", text:"Kazakhstan", disable: false },
			{ value:"KE", text:"Kenya", disable: false },
			{ value:"KI", text:"Kiribati", disable: false },
			{ value:"KP", text:"Korea, Democratic People's Republic of", disable: false },
			{ value:"KR", text:"Korea, Republic of", disable: false },
			{ value:"KW", text:"Kuwait", disable: false },
			{ value:"KG", text:"Kyrgyzstan", disable: false },
			{ value:"LA", text:"Lao People's Democratic Republic", disable: false },
			{ value:"LV", text:"Latvia", disable: false },
			{ value:"LB", text:"Lebanon", disable: false },
			{ value:"LS", text:"Lesotho", disable: false },
			{ value:"LR", text:"Liberia", disable: false },
			{ value:"LY", text:"Libya", disable: false },
			{ value:"LI", text:"Liechtenstein", disable: false },
			{ value:"LT", text:"Lithuania", disable: false },
			{ value:"LU", text:"Luxembourg", disable: false },
			{ value:"MO", text:"Macao", disable: false },
			{ value:"MK", text:"Macedonia, the former Yugoslav Republic of", disable: false },
			{ value:"MG", text:"Madagascar", disable: false },
			{ value:"MW", text:"Malawi", disable: false },
			{ value:"MY", text:"Malaysia", disable: false },
			{ value:"MV", text:"Maldives", disable: false },
			{ value:"ML", text:"Mali", disable: false },
			{ value:"MT", text:"Malta", disable: false },
			{ value:"MH", text:"Marshall Islands", disable: false },
			{ value:"MQ", text:"Martinique", disable: false },
			{ value:"MR", text:"Mauritania", disable: false },
			{ value:"MU", text:"Mauritius", disable: false },
			{ value:"YT", text:"Mayotte", disable: false },
			{ value:"MX", text:"Mexico", disable: false },
			{ value:"FM", text:"Micronesia, Federated States of", disable: false },
			{ value:"MD", text:"Moldova, Republic of", disable: false },
			{ value:"MC", text:"Monaco", disable: false },
			{ value:"MN", text:"Mongolia", disable: false },
			{ value:"ME", text:"Montenegro", disable: false },
			{ value:"MS", text:"Montserrat", disable: false },
			{ value:"MA", text:"Morocco", disable: false },
			{ value:"MZ", text:"Mozambique", disable: false },
			{ value:"MM", text:"Myanmar", disable: false },
			{ value:"NA", text:"Namibia", disable: false },
			{ value:"NR", text:"Nauru", disable: false },
			{ value:"NP", text:"Nepal", disable: false },
			{ value:"NL", text:"Netherlands", disable: false },
			{ value:"NC", text:"New Caledonia", disable: false },
			{ value:"NZ", text:"New Zealand", disable: false },
			{ value:"NI", text:"Nicaragua", disable: false },
			{ value:"NE", text:"Niger", disable: false },
			{ value:"NG", text:"Nigeria", disable: false },
			{ value:"NU", text:"Niue", disable: false },
			{ value:"NF", text:"Norfolk Island", disable: false },
			{ value:"MP", text:"Northern Mariana Islands", disable: false },
			{ value:"NO", text:"Norway", disable: false },
			{ value:"OM", text:"Oman", disable: false },
			{ value:"PK", text:"Pakistan", disable: false },
			{ value:"PW", text:"Palau", disable: false },
			{ value:"PS", text:"Palestinian Territory, Occupied", disable: false },
			{ value:"PA", text:"Panama", disable: false },
			{ value:"PG", text:"Papua New Guinea", disable: false },
			{ value:"PY", text:"Paraguay", disable: false },
			{ value:"PE", text:"Peru", disable: false },
			{ value:"PH", text:"Philippines", disable: false },
			{ value:"PN", text:"Pitcairn", disable: false },
			{ value:"PL", text:"Poland", disable: false },
			{ value:"PT", text:"Portugal", disable: false },
			{ value:"PR", text:"Puerto Rico", disable: false },
			{ value:"QA", text:"Qatar", disable: false },
			{ value:"RE", text:"Réunion", disable: false },
			{ value:"RO", text:"Romania", disable: false },
			{ value:"RU", text:"Russian Federation", disable: false },
			{ value:"RW", text:"Rwanda", disable: false },
			{ value:"BL", text:"Saint Barthélemy", disable: false },
			{ value:"SH", text:"Saint Helena, Ascension and Tristan da Cunha", disable: false },
			{ value:"KN", text:"Saint Kitts and Nevis", disable: false },
			{ value:"LC", text:"Saint Lucia", disable: false },
			{ value:"MF", text:"Saint Martin (French part)", disable: false },
			{ value:"PM", text:"Saint Pierre and Miquelon", disable: false },
			{ value:"VC", text:"Saint Vincent and the Grenadines", disable: false },
			{ value:"WS", text:"Samoa", disable: false },
			{ value:"SM", text:"San Marino", disable: false },
			{ value:"ST", text:"Sao Tome and Principe", disable: false },
			{ value:"SA", text:"Saudi Arabia", disable: false },
			{ value:"SN", text:"Senegal", disable: false },
			{ value:"RS", text:"Serbia", disable: false },
			{ value:"SC", text:"Seychelles", disable: false },
			{ value:"SL", text:"Sierra Leone", disable: false },
			{ value:"SG", text:"Singapore", disable: false },
			{ value:"SX", text:"Sint Maarten (Dutch part)", disable: false },
			{ value:"SK", text:"Slovakia", disable: false },
			{ value:"SI", text:"Slovenia", disable: false },
			{ value:"SB", text:"Solomon Islands", disable: false },
			{ value:"SO", text:"Somalia", disable: false },
			{ value:"ZA", text:"South Africa", disable: false },
			{ value:"GS", text:"South Georgia and the South Sandwich Islands", disable: false },
			{ value:"SS", text:"South Sudan", disable: false },
			{ value:"ES", text:"Spain", disable: false },
			{ value:"LK", text:"Sri Lanka", disable: false },
			{ value:"SD", text:"Sudan", disable: false },
			{ value:"SR", text:"Suriname", disable: false },
			{ value:"SJ", text:"Svalbard and Jan Mayen", disable: false },
			{ value:"SZ", text:"Swaziland", disable: false },
			{ value:"SE", text:"Sweden", disable: false },
			{ value:"CH", text:"Switzerland", disable: false },
			{ value:"SY", text:"Syrian Arab Republic", disable: false },
			{ value:"TW", text:"Taiwan, Province of China", disable: false },
			{ value:"TJ", text:"Tajikistan", disable: false },
			{ value:"TZ", text:"Tanzania, United Republic of", disable: false },
			{ value:"TH", text:"Thailand", disable: false },
			{ value:"TL", text:"Timor-Leste", disable: false },
			{ value:"TG", text:"Togo", disable: false },
			{ value:"TK", text:"Tokelau", disable: false },
			{ value:"TO", text:"Tonga", disable: false },
			{ value:"TT", text:"Trinidad and Tobago", disable: false },
			{ value:"TN", text:"Tunisia", disable: false },
			{ value:"TR", text:"Turkey", disable: false },
			{ value:"TM", text:"Turkmenistan", disable: false },
			{ value:"TC", text:"Turks and Caicos Islands", disable: false },
			{ value:"TV", text:"Tuvalu", disable: false },
			{ value:"UG", text:"Uganda", disable: false },
			{ value:"UA", text:"Ukraine", disable: false },
			{ value:"AE", text:"United Arab Emirates", disable: false },
			{ value:"UM", text:"United States Minor Outlying Islands", disable: false },
			{ value:"UY", text:"Uruguay", disable: false },
			{ value:"UZ", text:"Uzbekistan", disable: false },
			{ value:"VU", text:"Vanuatu", disable: false },
			{ value:"VE", text:"Venezuela, Bolivarian Republic of", disable: false },
			{ value:"VN", text:"Viet Nam", disable: false },
			{ value:"VG", text:"Virgin Islands, British", disable: false },
			{ value:"VI", text:"Virgin Islands, U.S.", disable: false },
			{ value:"WF", text:"Wallis and Futuna", disable: false },
			{ value:"EH", text:"Western Sahara", disable: false },
			{ value:"YE", text:"Yemen", disable: false },
			{ value:"ZM", text:"Zambia", disable: false },
			{ value:"ZW", text:"Zimbabwe", disable: false }
		];

