import _ from 'lodash';
import Errors from './formerrors.js';

class BaseForm
{
    constructor(data, options)
    {
        this.originalData = data;
        this.options = options;
        // used to prevent resetting the booleans to a string
        this.booleans = [];

        for (let field in data) {
            this[field] = data[field];
            if (typeof data[field] == 'boolean') {
            	this.booleans.push(field);
            }
        }

        this.errors = new Errors();
        this.foundItem = false;
        this.getLoading = false;
        this.generalmsg = "";
    }

    /**
    * When the get completes the function is called to allow child object to pre-configure data before resolve
    */
    beforeGetResolve(data) {
    	// basically an abstract method
    }

    setFoundStatus(active) {
    	this.foundItem = active;
    }

    getFoundStatus() {
    	return this.foundItem;
    }

    /**
    * Get is different process to submit so it duplicated i know
    */
    get(url) {
        return new Promise((resolve, reject) => {
            if (! this.beforeGetPost()) {
            	return;
            } else {
        		this.getLoading = true;
                axios.post(url, this.extractData())
                    .then(response => {
                        if (! _.isEmpty(response.data)) {
                            this.setFoundStatus(true);
                        } else {
                            this.setFoundStatus(false);
                        }
        				this.getLoading = false;
                        this.beforeGetResolve(response.data);
                        resolve(response.data);
                    })
                    .catch(errors => {
        				this.getLoading = false;
                        this.setFoundStatus(false);
                        reject(errors.response.data.errors);
                    });
            }
        });
    }

    extractData() {
        let data = {};
        for (let property in this.originalData) {
            data[property] = this[property];
        }
        data.options = this.options;
        return data;
    }

    submit(requestType, url) {
        return new Promise((resolve, reject) => {
            axios[requestType](url, this.extractData())
                .then(response => {
                    this.foundItem = true;
                    this.onSuccess(response.data);
                    resolve(response.data);
                })
                .catch(errors => {
                    if (errors.response.data.hasOwnProperty('errors')) {
                        this.onFail(errors.response.data.errors);
                        reject(errors.response.data.errors);
                    } else {
                        reject(errors.response);
                    }

                });
            });
    }

    onSuccess(data) {
        // at the moment everything is handled by the caller
    }

    onFail(errors) {
        this.errors.record(errors);
    }

    post(url) {
        return this.submit("post", url);
    }

    put(url) {
        return this.submit("put", url);
    }

    /**
    * Validation prior to calling the get Axios post 
    * returns true if validation passed and false to stop the call
    */
    beforeGetPost() {
    	return true;
    }

    reset() {
        this.setFoundStatus(false);
        this.generalmsg = "";
        for (let field in this.originalData) {
            if (this[field] != undefined && ! this.booleans.includes(field)) {
                this[field] = "";
            }
        }
        this.errors.clear();
    }

    getErrors(field) {
        return this.errors.get(field);
    }

    anyErrors() {
        if (this.errors.any()) return true;
    }

    clearErrors(field) {
        this.errors.clear(field);
    }

    hasErrors(field) {
        if (field == undefined || this.errors == undefined) {
            return false;
        }
        let result = this.errors.has(field);
        return result;
    }

    // take a set of contact data and fill into the object
    // countrydata is different and is its own object so upon finding those fields
    // add into the object
    copyData(fromData, withCountry = true, exclusion = []) {
       
        if (withCountry && this.hasOwnProperty('countrydata')) {
            this.copyCountryData(fromData);
        }

        for (let property in this.originalData) {

            // some properties might be excluded from copy (like email)
            if (exclusion.indexOf(property) > -1) continue;

            if (fromData.hasOwnProperty(property)) {
                if (typeof this.originalData[property] == 'object' || typeof this.originalData[property] == 'array') {
                    this.copyData(fromData[property]);
                } else {
                    this[property] = fromData[property];
                }
            }
        }
        return this;
    }

    // Country data is an object and often the data is flattened in the fromData
    // so this will take the flattened info and add it correctly
    copyCountryData(fromData) {
        if (fromData.hasOwnProperty('country') && fromData.hasOwnProperty('countrycode')) {
            this.countrydata.setCountryValue({text: fromData.country, value: fromData.countrycode});
        } else {
            if (fromData.hasOwnProperty('country')) this.countrydata.country = fromData.country;
            if (fromData.hasOwnProperty('countrycode')) this.countrydata.country = fromData.countrycode;
        }
        if (fromData.hasOwnProperty('state') && fromData.hasOwnProperty('statecode')) {
            this.countrydata.setStateValue({text: fromData.state, value: fromData.statecode});
        } else {
            if (fromData.hasOwnProperty('state')) this.countrydata.state = fromData.state;
            if (fromData.hasOwnProperty('statecode')) this.countrydata.statecode = fromData.statecode;
        }
        if (fromData.hasOwnProperty('postcode')) this.countrydata.postcode = fromData.postcode;
        if (fromData.hasOwnProperty('vat_id')) this.countrydata.vat_id = fromData.vat_id;
    }


}

export default BaseForm;