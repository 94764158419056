import Errors from './formerrors.js';
import {CountryList} from './countrycodeslist.js';
import {StateList} from './statecodeslist.js';


class Countries
{
	// data should be country, countrycode
	constructor(data)
	{
		this.originalData = data;
		this.states = [];

		for (let field in data)	{
			this[field] = data[field];
		}

		this.nozip = ["AO","AG","AW","BS","BZ","BJ","BW","BF","BI","CM","CF","KM","CG","CD","CK","CI","DJ",
                "DM","GQ","ER","FJ","TF","GM","GH","GD","GN","GY","HK","IE","JM","KE","KI","MO","MW",
                "ML","MR","MU","MS","NR","AN","NU","KP","PA","QA","RW","KN","LC","ST","SA","SC","SL",
                "SB","SO","ZA","SR","SY","TZ","TL","TK","TO","TT","TV","UG","AE","VU","YE","ZW"];

		this.withVat = ["CY", "NL", "IT", "ES", "FR", "HU", "HR", "BE", "LU", "MC", "GB", "PT", "SE", "LV", "PL", "CZ", "RO", "BG", "FI", "EE", "MT", "DE", "LT", "DK", "GR", 
			      "SK", "AT", "IE", "SI", "IM"];

    	// the large code arrays are exported out to the constants files
	    this.countryList = CountryList;
		this.stateList = StateList;	    

		this.errors = new Errors();
	}

	setCountryValue(value) {
    	this.state = '';
    	this.statecode = '';
    	this.states = [];
		this.postcode = '';
 	    if (typeof value == 'undefined' || value == null) {
 	    	this.country = '';
 	    	this.countrycode = '';
 	    	return;
 	    }

		this.country = value.text;
		this.countrycode = value.value;
		this.setAvailableStates(value);
	}

	setStateValue(value) {
 	    if (value == undefined || value == null) {
 	    	this.state = '';
 	    	this.statecode = '';
			this.postcode = '';
 	    	return;
 	    }
		this.state = value.text;
		this.statecode = value.value;
	}

	setAvailableStates(selectedCountry) {
	    this.states = this.stateList.filter(function (state) {
	          return state.country == selectedCountry.value;
	        }, selectedCountry);
	}


	hasZip() {
		if (this.nozip.indexOf(this.countrycode) > -1) {
			this.postcode = '';
			return false
		} else {
			return true;
		}
	}

	hasVat() {
		return (this.withVat.indexOf(this.countrycode) > -1);
	}

    reset() {
        for (let field in this.originalData) {
            if (typeof this[field] != 'boolean') {
                this[field] = "";
            }
        }
        this.states = {};
        this.errors.clear();
    }

    extractData() {
    	let data = {};
        for (let property in this.originalData) {
        	data[property] = this[property];
        }
        return data;
    }

}

export default Countries;