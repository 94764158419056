<template>
    <div class="col-12 col-md-10">
        <div class="row mx-1" v-if="masterInvoice.action > 0">
            <div class="col-12 bg-red-light p-3 mb-3 rounded ">
                <i class="far fa-exclamation-circle mr-2"></i><span class="">This invoice has been emailed to the customer</span>
            </div>
        </div>
        <form method="post" autocomplete="off" @submit.prevent="doNext" @keydown="form.clearErrors($event.target.name)">
            <div class="row">
                <div class="col-6">
                    <div class="card">
                        <div class="card-header">Bill To</div>
                        <div class="card-body">
                            <div class="align-items-center">
                                <div class="row">
                                    <div class="col" v-text="masterForm.email"></div>
                                </div>
                                <div class="row">
                                    <div class="col">{{ masterForm.firstname}} {{ masterForm.lastname}}</div>
                                </div>
                                <div class="row">
                                    <div class="col" v-text="masterForm.company"></div>
                                </div>
                                <div class="row">
                                    <div class="col" v-text="masterForm.addr1"></div>
                                </div>
                                <div class="row">
                                    <div class="col" v-text="masterForm.addr2"></div>
                                </div>
                                <div class="row">
                                    <div class="col">{{ masterForm.suburb }} {{ masterForm.countrydata.state }} {{ masterForm.countrydata.postcode }}</div>
                                </div>
                                <div class="row">
                                    <div class="col" v-text="masterForm.countrydata.country"></div>
                                </div>
                                <div class="row" v-if="hasMasterReller()">
                                    <div class="col">On behalf of {{ masterReseller.firstname }} {{ masterReseller.lastname }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card">
                        <div class="card-header">Header details</div>
                        <div class="card-body">
                            <div class="align-items-center">
                                <div class="form-inline">
                                    <label class="form-label col-5" for="invoicenum">Invoice Number:</label>
                                    <div class="col mb-2 bg-grey-light border border-grey-darker rounded">{{ formattedInvNumber }}</div>
                                </div>
                                <div class="form-inline">
                                    <label class="form-label col-5" for="invoicedate">Invoice Date:</label>
                                    <div class="col mb-2 bg-grey-light border border-grey-darker rounded" >{{ moment(form.created_at).format('DD-MMM-YYYY') }}</div>
                                </div>
                                <div class="form-inline">
                                    <label class="form-label col-5" for="reference">Reference:</label>
                                    <input class="form-control form-control-sm col mb-2" type="text" name="reference" v-model="form.reference">
                                    <span class="text-red text-sm" v-if="form.hasErrors('reference')" v-text="form.errors.get('reference')"></span>
                                </div>
                                <div class="form-inline">
                                    <label class="form-label col-5" for="due">Due Date:</label>
                                    <div class="input-group input-group-sm">
                                        <input class="form-control form-control-sm col" type="text" name="due" v-model="form.due_by">
                                        <div class="input-group-append">
                                            <b-form-datepicker
                                              id="dueby-cal"
                                              v-model="duebyPick"
                                              size="sm"
                                              button-only
                                              right
                                              locale="en"
                                              @selected="setDueBy"
                                              selected-variant="success" 
                                              today-variant="info"
                                            ></b-form-datepicker>
                                        </div>
                                        <span class="text-red text-sm" v-if="form.hasErrors('due_by')" v-text="form.errors.get('due_by')"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-1">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <span class="">Invoice Lines</span>
                            <span class="float-right"><a href="" @click.prevent="addRow"><i class="fas fa-plus"></i></a></span>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-5"><span class="ml-2">Description</span></div>
                                <div class="col-2 text-right">Qty</div>
                                <div class="col-2 text-right">Price</div>
                                <div class="col-3 text-right">Total</div>
                            </div>
                            <div v-for="(line, index) in form.invLines">
                                <invoice-line :invoiceLine="line" :prodList="productList" :key="renderkey + index" :index="index" @line-changed="doLineChanged"></invoice-line>
                            </div>                                
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-1 justify-content-end">
                <div class="col-6">
                    <div class="card">
                        <div class="card-header">Comment</div>
                        <textarea 
                            :style="commentStyle" 
                            id="comment" 
                            class="border-none w-100 
                            rows=4" 
                            v-on:keydown.enter="$event.stopPropagation()"
                            v-model="form.comment"></textarea>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card">
                        <div class="container-fluid no-gutter" id="totals" ref="totals">
                            <div class="p-2">
                                <div class="row d-flex justify-content-end align-items-center">
                                    <label class="form-label col-4" for="total">Total:</label>
                                    <span class="form-control form-control-sm col-4 offset-4 mb-2 text-right" name="total" v-text="formattedSubTotal"></span>
                                    <span class="text-red text-sm" v-if="form.hasErrors('lines_total')" v-text="form.errors.get('lines_total')"></span>

                                </div>
                                <div class="row d-flex justify-content-end align-items-center">
                                    <label class="form-label col-4" for="discount">Discount:</label>
                                    <div class="col-3 offset-1">
                                        <div class="input-group input-group-sm">
                                            <input class="form-control text-right" type="text" name="discount" v-model="form.discount">
                                            <div class="input-group-append">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="form-control form-control-sm col-4 text-right mb-2" name="discounttotal" v-text="getFormattedDecimal(discountTotal, 1)"></span>
                                </div>
                                <div class="row d-flex justify-content-end align-items-center">
                                    <label class="form-label col-4" for="tax">GST:</label>
                                    <div class="col-3 offset-1">
                                        <div class="input-group input-group-sm">
                                            <input class="form-control text-right" type="text" name="gst" v-model="form.tax">
                                            <div class="input-group-append">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="form-control form-control-sm col-4 text-right mb-2" v-text="getFormattedDecimal(taxTotal,1)"></span>
                                </div>
                                <div class="row d-flex justify-content-end align-items-center">
                                    <label class="form-label col-4" for="grandtotal">Invoice Total:</label>
                                    <div class="col-3 offset-1">
                                        <select class="form-control form-control-sm" v-model="form.currency">
                                            <option value="0">USD</option>
                                            <option value="1">AUD</option>
                                        </select>
                                    </div>
                                    <span class="form-control form-control-sm col-4 text-right" name="grandtotal" v-text="getFormattedDecimal(theGrandTotal)"></span>
                                    <span class="text-red text-sm" v-if="form.hasErrors('grandtotal')" v-text="form.errors.get('grandtotal')"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 bg-white border mt-2 rounded">
                <div class="row">
                    <div class="col-1" v-if="allowBack">
                            <button 
                                class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                                type="button" 
                                @click.prevent="doCancel"><i class="fal fa-arrow-alt-right mr-2"></i>Return</button>
                    </div>
                    <div class="col-11 d-inline-flex justify-content-end">
                        <div class="mr-4">
                            <button 
                                class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                                type="button" 
                                @click.prevent="doChangePage('reseller')" 
                                v-if="allowBack"><i class="fal fa-arrow-alt-left mr-2"></i>Back</button>
                        </div>
                        <div class="mr-2">
                            <button 
                                class="border bg-grey-lightest my-2 px-2 py-1 rounded px-2" 
                                type="button" 
                                :disabled="form.anyErrors()"
                                @click.prevent="doSave"><i class="fal fa-save mr-2"></i>Save</button>
                        </div>
                        <div class="" v-if="allowBack">
                            <button 
                                class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                                type="button" 
                                :disabled="form.anyErrors() || notOkForNext()"
                                @click.prevent="doChangePage('final')"><i class="fal fa-arrow-alt-right mr-2"></i>{{ nextText }}</button>
                        </div>
                        <div class="" v-else>
                            <button 
                                class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                                type="button" 
                                @click.prevent="doCancel"><i class="fal fa-arrow-alt-right mr-2"></i>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

import _ from 'lodash'
import BaseForm from './contact/baseform.js'
import invoiceline from './InvoiceLine';

var moment = require('moment');

export default {
    // Cannot use props because of the generic nature of Invoicing and the "tabs"
    components: {
        'invoice-line': invoiceline

    },

    data() {
        return {
            invoiceSaved: false,
            masterForm: {},
            masterInvoice: {},
            moment : moment,
            masterReseller: {},
            form: {}, //this.initObject(),
            duebyPick: '',
            commentStyle: '',
            allowBack: true,
            productList: {},
            renderkey: 0,
            initdone: false,
            nextText: 'Finish'
        }
    },

    created() {
        window.events.$on("invoice_reload", data => {
            this.doReload();
        });
        this.doReload();
    },

    mounted() {
        axios.get('/invoice/productlist')
            .then(response => {
                this.productList = response.data;
            });    
    },

    computed: {
        formattedInvNumber() {
            return "00" + this.form.invnum;
        },

        currencyStr() {
            return (this.form.currency == 0 ? "USD" : "AUD");
        },

        discountTotal() {
            if (this.form.discount == 0 || this.form.lines_total == 0) {
                return 0;
            }
            return _.round(this.form.lines_total * (this.form.discount / 100),2);
        },

        taxTotal() {
            if (this.form.tax == 0 || this.form.lines_total == 0) {
                return 0;
            }
            return _.round((this.form.lines_total - this.form.discount_value) * (this.form.tax / 100),2);
        },

        theGrandTotal() {
            return parseFloat(this.form.lines_total) - parseFloat(this.form.discount_value) + parseFloat(this.form.tax_value);
        },

        formattedSubTotal() {
            return this.getFormattedDecimal(this.form.lines_total, 1);
        }

    },

    watch: {
        duebyPick: function(val) {
            this.form.due_by = this.moment(val).format('DD-MMM-YYYY');
        },

        discountTotal: function(val) {
            this.form.discount_value = _.round(val,2);
        },

        taxTotal: function(val) {
            this.form.tax_value = _.round(val,2);
        },

        theGrandTotal: function(val) {
            this.form.grandtotal = _.round(val,2);
        }
    },

    methods: {

        doReload() {
            // want to clear the object if this is a reload
            this.form = this.initObject();

            this.renderkey += 10;
            if (this.$parent.wantEditInvoice) {
                if (! _.isEmpty(this.$parent.masterInvoice)) {
                    this.masterInvoice = this.$parent.masterInvoice;
                    this.masterForm = this.$parent.masterForm;
                    _.assign(this.form, this.masterInvoice);
                    _.assign(this.form.invLines, this.form.invoice_lines);

                    this.form.customer_id = this.masterInvoice.customer_id;
                    this.masterForm.customer_id = this.masterInvoice.customer_id;
                    this.form.email = this.masterForm.email;
                    this.form.foundItem = true;
                    this.form.setFoundStatus(true);
                    this.allowBack = false;
                    this.form.due_by = this.moment(this.form.due_by).format('DD-MMM-YYYY');
                    this.duebyPick = this.moment(this.$parent.masterInvoice.due_by).format('YYYY-MM-DD'); // the due_by date will be updated by the watcher
                    this.form.grandtotal = parseFloat(this.masterInvoice.total);
                    this.doRefresh();
                }
                if (! _.isEmpty(this.$parent.masterReseller)) {
                    this.masterReseller = this.$parent.masterReseller;
                    this.form.reseller_id = this.masterReseller.reseller_id;
                    if (this.form.reseller_id > 0) {
                        this.form.discount = 10;
                    }
                }
            } else {
                this.allowBack = true;
                this.masterInvoice = {};
                if (! _.isEmpty(this.$parent.masterForm)) {
                    this.masterForm = this.$parent.masterForm.extractData();
                    this.form.customer_id = this.masterForm.customer_id;
                    this.form.email = this.masterForm.email;
                } 
                if (! _.isEmpty(this.$parent.masterReseller)) {
                    this.masterReseller = this.$parent.masterReseller.extractData();
                    this.form.reseller_id = this.masterReseller.reseller_id;
                    if (this.form.reseller_id > 0) {
                        this.form.discount = 10;
                    }
                }
                this.form.due_by = this.moment().add(30,'d').format('DD-MMM-YYYY');
                this.duebyPick = this.moment().add(30,'d').format('YYYY-MM-DD'); // the due_by date will be updated by the watcher
                this.form.tax = this.masterForm.tax;
                this.form.currency = this.masterForm.currency;
                this.form.invLines.push(this.makeNewLine());
                this.form.created_at = this.moment();
                
                axios.get('/invoice/number')
                    .then(response => {
                        this.form.invnum = response.data;
                    });
            }

            // sets the comment height to be the initial same as the totals but ongoing changes will not change comment height
            this.$nextTick(function() {
                let totals = this.$refs.totals;
                if (totals) {
                    this.commentStyle = "height: " + (totals.offsetHeight - 48) + 'px';     
                } else {
                    this.commentStyle = "height: 100px";     
                }
            });

        },

        initObject() {
            this.initdone = true;
            return new BaseForm({
                    email: '',
                    customer_id: 0,
                    reseller_id: 0,
                    invnum: 0,
                    reference: '',
                    due_by: '',
                    due_by_value: '',
                    currency: 0,
                    invLines: [],
                    lines_total:0,
                    discount: 0,
                    discount_value: 0,
                    tax: 0,
                    tax_value: 0,
                    grandtotal: 0,
                    comment: '',
                    delta_user_id: 0,
                    delta_license_id: 0,
                    regcode: '',
                    action: 0,
                    invoice_slug: '',
                    id: 0
                },
                {}
            );
        },

        /**
        * Create a new invoice line object
        */
        makeNewLine() {
            return {
                   invoice_id: 0,
                   row_position: this.form.invLines.length, 
                   code: '',
                   line_desc: '',
                   line_details: '',
                   qty: 1,
                   unit: 0,
                   total: 0,
                   license_id: 0
                };           
        },

        /**
        * Event from InvoiceLine to save or delete an invoice line
        */
        doLineChanged(value) {
            switch (value.action) {
                case 'save': this.updateLineData(value); break;
                case 'delete': this.deleteRow(value); break;
            }
        },

        /**
        * Save Event from InvoiceLine to update the individual invoice line
        */
        updateLineData(value) {
            if (value == undefined || value.line == undefined || value.index == -1) return;

            if (value.line.code == undefined) {
                this.form.invLines[value.index].code = "";
                this.form.invLines[value.index].line_desc = "";
                this.form.invLines[value.index].line_details = ""
                this.form.invLines[value.index].unit = 0;
                this.form.invLines[value.index].total = 0;
                return;
            }
            this.form.invLines[value.index].code = value.line.code;
            this.form.invLines[value.index].line_desc = value.line.line_desc;
            this.form.invLines[value.index].line_details = value.line.line_details;
            this.form.invLines[value.index].qty = value.line.qty;
            this.form.invLines[value.index].unit = parseFloat(value.line.unit);
            this.form.invLines[value.index].total = parseFloat(value.line.total);
            this.form.invLines[value.index].license_id = value.line.license_id;
            this.form.invLines[value.index].action = value.line.action;
            if (value.makeUSD) {
                this.form.currency = 0;
                this.form.tax = 0;
            }
            if (!_.isEmpty(value.license)) {
                this.form.delta_user_id = value.license.user_id;
                this.form.delta_license_id = value.license.id;
            }
            this.doRefresh();
        },

        /**
        * Add the invoice line
        */
        addRow() {
            this.form.invLines.push(this.makeNewLine());
        },

        /**
        * Delete Event from InvoiceLine to delete the invoice line
        */
        deleteRow(value) {
            if (this.form.invLines.length > 0) {
                this.form.invLines.splice(value.index, 1);
                this.doRefresh();
            }
        },

        doRefresh() {
            let self = this;
            this.form.lines_total = 0;
            this.form.invLines.forEach(function(aline) {
                self.form.lines_total += parseFloat(aline.total);
            });
            this.calcNextPage();
        },

        /**
        * not used (yet)
        */
        doResetForm() {
            this.form.reset();
        },

        /**
        * Can the user move on from here, Next button
        */
        notOkForNext() {
            return (parseFloat(this.form.grandtotal) == 0 || this.form.reference == '');
        },

        /**
        * Save button pressed
        */
        doSave() {
            this.save();
        },

        /**
        * Actual Save or Update the invoice as a draft, once emailed the status will be changed to 1 - sent
        * The process is this.form.post or this.form.put which is in baseform.js
        */
        save(changePage = '') {
            this.form.due_by_value = this.duebyPick;
            let action = this.form.getFoundStatus() ? "put" : "post";
            this.form[action]('/invoice')
                .then(data => {
                    flash(data.message);
                    this.invoiceSaved = true;
                    this.form.invoice_slug = data.invoice.invoice_slug;
                    this.form.id = data.invoice.id;
                    if (changePage !== '') {
                        this.gotoNextPage(changePage);
                    }
                    if (this.$parent.wantEditInvoice) {
                        this.$parent.refreshInvoiceList = true;
                        this.gotoNextPage('invoice-list');    
                    }
                }).catch(errors => {
                    if (errors.hasOwnProperty('statusText')) {
                        flash(errors.statusText);
                    } 
                });
        },

        // Move to the next page, saving if new customer
        doNext() {
            if (! this.invoiceSaved && ! this.form.getFoundStatus()) {
                this.save(true);
            } else {
                this.gotoNextPage('final');
            }
        },

        /**
        * Change to another page (back/forward)
        */
        doChangePage(page) {
            if (! this.invoiceSaved && ! this.form.getFoundStatus()) {
                this.save(page);
            } else {
                this.gotoNextPage(page);
            }
        },

        /**
        * If a license is required for the product then go to the gen license page
        * or if its a 
        */
        gotoNextPage(page) {
            if (page == 'final') {
                page = this.calcNextPage();
            }
            this.form.customer = this.masterForm;
            this.$emit("form-action", {
                action: page,
                form: this.form,
                reseller: this.masterReseller
            });
        },

        calcNextPage() {
            let page = "invoice-list";
            let productCode = this.form.invLines[0].code.split("-");
            const needlic = ['SDSS', 'SDO', 'SDMY', 'DXPSM', 'DXPOM', 'DXPPM'];
            const needsub = ['R', 'SR', 'SRC', 'RC'];

            if (productCode.length > 1 && needsub.indexOf(productCode[1]) > -1 ) {
                page = 'deltasub'; // not done yet
                this.nextText = "To Subscription";
            } else if (needlic.indexOf(productCode[0]) > -1) {
                page = 'deltauser';
                this.nextText = "Make License";
            } else {
                page = 'invoice-list';
                this.nextText = "Finish";
            }
            return page;
        },

        doCancel() {
            this.$emit("form-action", {
                action: "invoice-list",
                form: this.form
            });
        },

        /**
        * Display the value as a currency $9,999.99 value
        */
        getFormattedDecimal(value, style = 0) {
            const formatter = new Intl.NumberFormat('en-US', {
              style: (style == 1) ? 'decimal' : 'currency',
              currency: 'USD',
              minimumFractionDigits: 2
            });
            return formatter.format(parseFloat(value));
        },

        setDueBy(val, date) {
            this.due_by = this.moment(date).format('DD-MMM-YYYY');
        },

        hasMasterReller() {
            return ! (_.isEmpty(this.masterReseller) || this.masterReseller.email == '');
        },


    }
}

</script>
