export const StateList = [
		{ value:"AA", text:"Armed Forces - USA/Canada", country: "US" },
		{ value:"AE", text:"Armed Forces - Europe", country: "US" },
		{ value:"AK", text:"Alaska", country: "US" },
		{ value:"AL", text:"Alabama", country: "US" },
		{ value:"AP", text:"Armed Forces - Pacific", country: "US" },
		{ value:"AR", text:"Arkansas", country: "US" },
		{ value:"AZ", text:"Arizona", country: "US" },
		{ value:"CA", text:"California", country: "US" },
		{ value:"CO", text:"Colorado", country: "US" },
		{ value:"CT", text:"Connecticut", country: "US" },
		{ value:"DC", text:"District of Columbia", country: "US" },
		{ value:"DE", text:"Delaware", country: "US" },
		{ value:"FL", text:"Florida", country: "US" },
		{ value:"GA", text:"Georgia", country: "US" },
		{ value:"HI", text:"Hawaii", country: "US" },
		{ value:"IA", text:"Iowa", country: "US" },
		{ value:"ID", text:"Idaho", country: "US" },
		{ value:"IL", text:"Illinois", country: "US" },
		{ value:"IN", text:"Indiana", country: "US" },
		{ value:"KS", text:"Kansas", country: "US" },
		{ value:"KY", text:"Kentucky", country: "US" },
		{ value:"LA", text:"Louisiana", country: "US" },
		{ value:"MA", text:"Massachusetts", country: "US" },
		{ value:"MD", text:"Maryland", country: "US" },
		{ value:"ME", text:"Maine", country: "US" },
		{ value:"MH", text:"Marshall Islands", country: "US" },
		{ value:"MI", text:"Michigan", country: "US" },
		{ value:"MN", text:"Minnesota", country: "US" },
		{ value:"MO", text:"Missouri", country: "US" },
		{ value:"MS", text:"Mississippi", country: "US" },
		{ value:"MT", text:"Montana", country: "US" },
		{ value:"NC", text:"North Carolina", country: "US" },
		{ value:"ND", text:"North Dakota", country: "US" },
		{ value:"NE", text:"Nebraska", country: "US" },
		{ value:"NH", text:"New Hampshire", country: "US" },
		{ value:"NJ", text:"New Jersey", country: "US" },
		{ value:"NM", text:"New Mexico", country: "US" },
		{ value:"NV", text:"Nevada", country: "US" },
		{ value:"NY", text:"New York", country: "US" },
		{ value:"OH", text:"Ohio", country: "US" },
		{ value:"OK", text:"Oklahoma", country: "US" },
		{ value:"OR", text:"Oregon", country: "US" },
		{ value:"PA", text:"Pennsylvania", country: "US" },
		{ value:"PR", text:"Puerto Rico", country: "US" },
		{ value:"RI", text:"Rhode Island", country: "US" },
		{ value:"SC", text:"South Carolina", country: "US" },
		{ value:"SD", text:"South Dakota", country: "US" },
		{ value:"TN", text:"Tennessee", country: "US" },
		{ value:"TX", text:"Texas", country: "US" },
		{ value:"UT", text:"Utah", country: "US" },
		{ value:"VA", text:"Virginia", country: "US" },
		{ value:"VI", text:"Virgin Islands", country: "US" },
		{ value:"VT", text:"Vermont", country: "US" },
		{ value:"WA", text:"Washington", country: "US" },
		{ value:"WI", text:"Wisconsin", country: "US" },
		{ value:"WV", text:"West Virginia", country: "US" },
		{ value:"WY", text:"Wyoming", country: "US" },

		{ value:"AB", text:"Alberta", country: "CA" },
		{ value:"BC", text:"British Columbia", country: "CA" },
		{ value:"MB", text:"Manitoba", country: "CA" },
		{ value:"NB", text:"New Brunswick", country: "CA" },
		{ value:"NL", text:"Newfoundland and Labrador", country: "CA" },
		{ value:"NS", text:"Nova Scotia", country: "CA" },
		{ value:"NT", text:"Northern Territories", country: "CA" },
		{ value:"NU", text:"Nunavut", country: "CA" },
		{ value:"ON", text:"Ontario", country: "CA" },
		{ value:"PE", text:"Prince Edward Island", country: "CA" },
		{ value:"QC", text:"Quebec", country: "CA" },
		{ value:"SK", text:"Saskatchewan", country: "CA" },
		{ value:"YT", text:"Yukon", country: "CA" },

		{ value:"CN-34", text:"Anhui Sheng", country:"CN" },
		{ value:"CN-11", text:"Beijing Shi", country: "CN" },
		{ value:"CN-50", text:"Chongqing Shi", country: "CN" },
		{ value:"CN-35", text:"Fujian Sheng", country: "CN" },
		{ value:"CN-62", text:"Gansu Sheng", country: "CN" },
		{ value:"CN-44", text:"Guangdong Sheng", country: "CN" },
		{ value:"CN-45", text:"Guangxi Zhuangzu Zizhiqu", country: "CN" },
		{ value:"CN-52", text:"Guizhou Sheng", country: "CN" },
		{ value:"CN-46", text:"Hainan Sheng", country: "CN" },
		{ value:"CN-13", text:"Hebei Sheng", country: "CN" },
		{ value:"CN-23", text:"Heilongjiang Sheng", country: "CN" },
		{ value:"CN-41", text:"Henan Sheng", country: "CN" },
		{ value:"HK-0", text:"Hong Kong", country: "CN" },
		{ value:"CN-42", text:"Hubei Sheng", country: "CN" },
		{ value:"CN-43", text:"Hunan Sheng", country: "CN" },
		{ value:"CN-32", text:"Jiangsu Sheng", country: "CN" },
		{ value:"CN-36", text:"Jiangxi Sheng", country: "CN" },
		{ value:"CN-22", text:"Jilin Sheng", country: "CN" },
		{ value:"CN-21", text:"Liaoning Sheng", country: "CN" },
		{ value:"MO-0", text:"Macau", country: "CN" },
		{ value:"CN-64", text:"Ningxia Huizu Zizhiqu", country: "CN" },
		{ value:"CN-63", text:"Qinghai Sheng", country: "CN" },
		{ value:"CN-61", text:"Shannxi Sheng", country: "CN" },
		{ value:"CN-37", text:"Shandong Sheng", country: "CN" },
		{ value:"CN-31", text:"Shanghai Shi", country: "CN" },
		{ value:"CN-14", text:"Shanxi Sheng", country: "CN" },
		{ value:"CN-51", text:"Sichuan Sheng", country: "CN" },
		{ value:"TW-0", text:"Taiwan", country: "CN" },
		{ value:"CN-12", text:"Tianjin Shi", country: "CN" },
		{ value:"CN-54", text:"Tibet", country: "CN" },
		{ value:"CN-65", text:"Xinjiang Uygur Zizhiqu", country: "CN" },
		{ value:"CN-53", text:"Yunnan Sheng", country: "CN" },
		{ value:"CN-33", text:"Zhejiang Sheng", country: "CN" },

		{ value:"JP-01", text:"Hokkaido", country: "JP" },
		{ value:"JP-02", text:"Aomori", country: "JP" },
		{ value:"JP-03", text:"Iwate", country: "JP" },
		{ value:"JP-04", text:"Miyagi", country: "JP" },
		{ value:"JP-05", text:"Akita", country: "JP" },
		{ value:"JP-06", text:"Yamagata", country: "JP" },
		{ value:"JP-07", text:"Fukushima", country: "JP" },
		{ value:"JP-08", text:"Ibaraki", country: "JP" },
		{ value:"JP-09", text:"Tochigi", country: "JP" },
		{ value:"JP-10", text:"Gunma", country: "JP" },
		{ value:"JP-11", text:"Saitama", country: "JP" },
		{ value:"JP-12", text:"Chiba", country: "JP" },
		{ value:"JP-13", text:"Tokyo", country: "JP" },
		{ value:"JP-14", text:"Kanagawa", country: "JP" },
		{ value:"JP-15", text:"Niigata", country: "JP" },
		{ value:"JP-16", text:"Toyama", country: "JP" },
		{ value:"JP-17", text:"Ishikawa", country: "JP" },
		{ value:"JP-18", text:"Fukui", country: "JP" },
		{ value:"JP-19", text:"Yamanashi", country: "JP" },
		{ value:"JP-20", text:"Nagano", country: "JP" },
		{ value:"JP-21", text:"Gifu", country: "JP" },
		{ value:"JP-22", text:"Shizuoka", country: "JP" },
		{ value:"JP-23", text:"Aichi", country: "JP" },
		{ value:"JP-24", text:"Mie", country: "JP" },
		{ value:"JP-25", text:"Shiga", country: "JP" },
		{ value:"JP-26", text:"Kyoto", country: "JP" },
		{ value:"JP-27", text:"Osaka", country: "JP" },
		{ value:"JP-28", text:"Hyogo", country: "JP" },
		{ value:"JP-29", text:"Nara", country: "JP" },
		{ value:"JP-30", text:"Wakayama", country: "JP" },
		{ value:"JP-31", text:"Tottori", country: "JP" },
		{ value:"JP-32", text:"Shimane", country: "JP" },
		{ value:"JP-33", text:"Okayama", country: "JP" },
		{ value:"JP-34", text:"Hiroshima", country: "JP" },
		{ value:"JP-35", text:"Yamaguchi", country: "JP" },
		{ value:"JP-36", text:"Tokushima", country: "JP" },
		{ value:"JP-37", text:"Kagawa", country: "JP" },
		{ value:"JP-38", text:"Ehime", country: "JP" },
		{ value:"JP-39", text:"Kochi", country: "JP" },
		{ value:"JP-40", text:"Fukuoka", country: "JP" },
		{ value:"JP-41", text:"Saga", country: "JP" },
		{ value:"JP-42", text:"Nagasaki", country: "JP" },
		{ value:"JP-43", text:"Kumamoto", country: "JP" },
		{ value:"JP-44", text:"Oita", country: "JP" },
		{ value:"JP-45", text:"Miyazaki", country: "JP" },
		{ value:"JP-46", text:"Kagoshima", country: "JP" },
		{ value:"JP-47", text:"Okinawa", country: "JP" },

		{ value:"ACT", text:"ACT", country: "AU" },
		{ value:"NSW", text:"NSW", country: "AU" },
		{ value:"NT", text:"NT", country: "AU" },
		{ value:"QLD", text:"QLD", country: "AU" },
		{ value:"SA", text:"SA", country: "AU" },
		{ value:"TAS", text:"TAS", country: "AU" },
		{ value:"VIC", text:"VIC", country: "AU" },
		{ value:"WA", text:"WA", country: "AU" },
	];
