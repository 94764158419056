<template>
    <div class="col-12">
        <div class="row" v-if="hasCompany">
            <div class="col-6">
                <div class="card shadow m-2">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-7" >
                                <div class="row">
                                    <div class="col-12">Invoiced to {{ masterInvoice.customer.company }}</div>
                                    <div class="col-12">Invoice: {{ masterInvoice.invnum }}</div>
                                    <div class="col-12">To: {{ masterInvoice.email }}</div>
                                </div>
                            </div>
                            <div class="col-5 text-right" v-html="getSentDate()"></div>
                        </div>
                        <div class="row d-flex align-items-center border-t border-grey-light my-1" v-if="allowEmailer">
                            <div class="col-12 custom-control custom-switch mt-2 d-flex justify-content-end">
                                <input type="checkbox" tabindex=18 class="custom-control-input" id="showEmailer" name="showEmailer" v-model="showEmailer">
                                <label for="showEmailer" class="custom-control-label">Send an Email</label>
                            </div>
                        </div>                        
                        <div class="row d-flex align-items-center border-t border-grey-light mt-1 pt-2" v-if="showEmailer">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-md-6 offset-md-3">
                                        <input type="text" class="form-control my-2" name="To" placeholder="Email To" v-model="emailer.to">
                                        <input type="text" class="form-control my-2" name="cc" placeholder="Email CC" v-model="emailer.cc">
                                        <input type="text" class="form-control my-2" name="bcc" placeholder="Email BCC" v-model="emailer.bcc">
                                        <div class="customer-control custom-switch my-2">
                                            <input type="checkbox" tabindex=18 class="custom-control-input" id="noAttach" name="noAttach" v-model="emailer.noAttach">
                                            <label for="noAttach" class="custom-control-label">Remove Attachment</label>
                                        </div>
                                        <div class="">Comma separate the CC or BCC without spaces</div>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center border-t border-grey-light mt-1 pt-2" v-if="! isRunning">
                                    <div class="col-12 d-flex justify-content-end" >
                                        <span v-if="canRemind()">
                                            <button class="border bg-grey-lightest my-2 px-2 py-1 rounded" @click.prevent="doEmail('reminder')"><i class="fal fa-arrow-alt-right mr-2"></i>Reminder To..</button>
                                        </span>
                                        <button class="border bg-grey-lightest my-2 px-2 py-1 rounded ml-1" @click.prevent="doEmail('invoice')"><i class="fal fa-arrow-alt-right mr-2"></i>Invoice To....</button>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center border-t border-grey-light mt-1 pt-2" v-if="isRunning">
                                    <div class="col-12 d-flex justify-content-end">
                                        <div class="fa-1x text-green">
                                            <i class="fas fa-spinner" :class="isRunning ? 'fa-pulse mr-2' : ''"></i> Sending email..
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-row align-items-center">
                            <div class="col-12 ml-3 mb-3">
                                <div>{{ masterInvoice.customer.firstname }} {{ masterInvoice.customer.lastname }}</div>
                                <div>{{ masterInvoice.customer.addr1 }}</div>
                                <div>{{ masterInvoice.customer.addr2 }}</div>
                                <div v-text="getSuburbData()"></div>
                                <div>{{ masterInvoice.customer.country }} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="card shadow m-2">
                    <div class="card-header">
                        <div class="">
                            <div v-if="hasReseller()">
                                <div class="row">
                                    <div class="col-6">Reseller for {{ masterReseller.company }}</div>
                                    <div class="col-6 text-right"><button @click.prevent="resendReseller">Resend Notice</button></div>
                                </div>
                            </div>
                            <div v-else>No Reseller</div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-row align-items-center" v-if="hasReseller()">
                            <div class="col-12 ml-3 mb-3">
                                <div>{{ masterReseller.firstname }} {{ masterReseller.lastname }}</div>
                                <div>{{ masterReseller.addr1 }}</div>
                                <div>{{ masterReseller.addr2 }}</div>
                                <div v-html="getResellerSuburb()"></div>
                            </div>
                        </div>
                        <div v-else>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="card m-2 shadow">
                    <div class="card-header">
                        <div class="">Sale Details</div>
                    </div>
                    <div class="card-body">
                        <div class="row align-items-center" v-for="line in masterInvoice.invoice_lines">
                            <div class="col-8">{{ line.line_desc }}</div>
                            <div class="col-1 text-right">{{ line.qty }}</div>
                            <div class="col-3 text-right">{{ line.total }}</div>
                        </div>
                        <div class="row border-t border-grey-light mt-3 pt-2">
                            <div class="col-6">Discount</div>
                            <div class="col-6 text-right">{{ masterInvoice.discount_value }}</div>
                        </div>
                        <div class="row">
                            <div class="col-6">GST</div>
                            <div class="col-6 text-right">{{ masterInvoice.tax_value }}</div>
                        </div>
                        <div class="row">
                            <div class="col-6">Total</div>
                            <div class="col-6 text-right">{{ getCurrency() }} {{ masterInvoice.total }}</div>
                        </div>
                        <div class="row border-t border-grey-light mt-2">
                            <div class="col-12 pt-2">{{ masterInvoice.comment }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6" v-if="hasLicense">
                <div class="card mt-2 shadow">
                    <div class="card-header">
                        <div class="">License Details</div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4">Reg Code</div>
                            <div class="col-8 text-right">{{ license.regcode }}</div>
                        </div>
                        <div class="row">
                            <div class="col-4">Expires</div>
                            <div class="col-8 text-right">{{ moment(license.po_expire).format('DD-MMM-YYYY') }}</div>
                        </div>
                        <div class="row">
                            <div class="col-4">Licensed to</div>
                            <div class="col-8 text-right">{{ license.regname }}</div>
                        </div>
                        <div class="row pt-3" v-if="masterInvoice.delta_license_id == 0">
                            <div class="col-12">The invoice doesn't have it's link to the license however there is a license associated with the Invoice Number {{ masterInvoice.invnum }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6" v-else>
                <div class="card mt-2 shadow">
                    <div class="card-header">
                        <div class="">License Details</div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col">This was not an SQL Delta license purchase</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 bg-white border mt-2 rounded d-inline-flex justify-content-end">
            <div class="">
                <button 
                    class="border bg-grey-lightest my-2 px-2 py-1 rounded" 
                    type="button" 
                    @click.prevent="doCancel"><i class="fal fa-arrow-alt-right mr-2"></i>Return</button>
            </div>
        </div>
</div>
</template>

<script>

import _ from 'lodash'

var moment = require('moment');

export default {

    data() {
        return {
            masterInvoice: {},
            masterReseller: {},
            form: {},
            license: {},
            moment: moment,
            hasLicense: false,
            hasCompany: false,
            allowEmailer: true,
            showEmailer: false,
            isRunning: false,
            emailer: {
                to: '',
                cc: '',
                bcc: '',
                noAttach: false
            }
        }
    },

    mounted() {
        window.events.$on("status_reload", data => {
            this.doReload();
        });
        this.doReload();

    },

    watch: {
        showEmailer: function(value) {
            if (value && this.emailer.to == '') {
                this.emailer.to = this.masterInvoice.email;
            }
        }

    },

    methods: {
        doReload() {
            this.allowEmailer = false;
            this.showEmailer = false;
            this.resetEmailer();
            this.license = {};
            this.hasLicense = {};
            this.masterInvoice = {};
            this.masterReseller = {};
            if (! _.isEmpty(this.$parent.masterInvoice)) {
                this.masterInvoice = this.$parent.masterInvoice;
                this.setFormData();
            }
            this.loadOrder();
            this.loadReseller();
        },

        setFormData() {
            this.form.qty = this.masterInvoice.invoice_lines[0].qty;
            this.form.productcode = this.masterInvoice.invoice_lines[0].code;
            this.form.license_id = this.masterInvoice.invoice_lines[0].license_id;
            this.form.unit = this.masterInvoice.invoice_lines[0].unit;
            this.form.reseller_id = this.masterInvoice.reseller_id;
            this.form.ponumber = this.masterInvoice.reference;
            this.form.billemail = this.masterInvoice.customer.email;
            this.form.invnum = this.masterInvoice.invnum;
            this.hasCompany = true;
            this.allowEmailer = this.masterInvoice.action < 3;
        },

        loadOrder() {
            axios.post('order/getinvoice', {invnum: this.form.invnum})
                .then(response => {
                    this.license = response.data.license;
                    this.hasLicense =  (this.license != null);
                });
        },

        loadReseller() {
            if (this.masterInvoice.reseller_id != 0) {
                axios.post('/reseller/get', {reseller_id: this.masterInvoice.reseller_id})
                    .then(response => {
                        this.masterReseller = response.data;
                });
            }
        },

        // Move to the next page, saving if new customer
        doCancel() {
            this.$emit("form-action", {
                action: 'invoice-list'
            });
        },

        doEmail(type) {
            if (this.emailer.to == '') {
                alert("You must have a To email address");
                return;
            }
            this.isRunning = true;
            axios.post('/invoice/email', {
                id: this.masterInvoice.id,
                type: type,
                email: this.emailer
            }).then(response => {
                this.resetEmailer();
                flash(response.data);
            }).catch(error => {
                this.isRunning = false;
                flash("Oops, that didn't work as expected");
            });
        },

        getCurrency() {
            return (this.masterInvoice.currency == 0) ? "US$" : "AU$";
        },

        getSentDate() {
            let sent = "";
            if (this.masterInvoice.sent == undefined) {
                sent = "<span class='text-red font-bold'>Invoice not Sent</span>";
            } else {
                sent = "<span class='text-green font-bold'>" + this.moment(this.masterInvoice.sent).format('DD-MMM-YYYY') + "</span>";
            }
            sent += "<br><span " + (this.moment(this.masterInvoice.due_by).isBefore() ? "class='text-red'" : "") + ">Due: " + this.moment(this.masterInvoice.due_by).format('DD-MMM-YYYY')+"</span>";
            if (this.masterInvoice.completed != undefined) {
                if (this.masterInvoice.action == 4) {
                    sent += "<br><span class='font-bold text-black'>Voided: " + this.moment(this.masterInvoice.completed).format('DD-MMM-YYYY')+"</span>";
                } else {
                    sent += "<br><span>Paid: " + this.moment(this.masterInvoice.completed).format('DD-MMM-YYYY')+"</span>";
                }
            }
            if (this.masterInvoice.last_reminder != undefined) {
                if (this.masterInvoice.action == 1) {
                    sent += "<br><span class='text-black'>Last Sent Reminder on: " + this.moment(this.masterInvoice.last_reminder).format('DD-MMM-YYYY')+" (x"+ this.masterInvoice.num_reminders + ")</span>";
                }
            }
            return sent;
        },

        resendReseller() {
            axios.post("/order/resendreseller", {
                    reseller_id: this.masterReseller.reseller_id,
                    license_id: this.license.id
                })
                .then(response => {
                    flash(response.data.message);
                })
        },

        hasReseller() {
            return ! ( (_.isEmpty(this.masterReseller) || ! _.isEmpty(this.masterReseller.message)) || typeof this.masterInvoice.reseller_id == 'undefined');
        },

        getSuburbData() {
            if (typeof this.masterInvoice === 'undefined') return '';

            return  this.masterInvoice.customer.suburb + ((! this.masterInvoice.customer.state) ? "" : " " + this.masterInvoice.customer.state) + " " + this.masterInvoice.customer.postcode;
        },

        getResellerSuburb() {
            if (typeof this.masterReseller === 'undefined') return '';
            if (typeof this.masterReseller.message !== 'undefined') return ''; // Could not be located error message

            return this.masterReseller.suburb + ((! this.masterReseller.countrydata.state) ? "" : " " + this.masterReseller.countrydata.state) + " " + this.masterReseller.countrydata.postcode + ((! this.masterReseller.countrydata.country) ? "" : "<br>" + this.masterReseller.countrydata.country);
        },

        canRemind() {
            return this.moment(this.masterInvoice.due_by).isBefore();
        },

        resetEmailer() {
            this.isRunning = false;
            this.emailer.to = "";
            this.emailer.cc = "";
            this.emailer.bcc = "";
            this.emailer.noAttach = false;
        }


    }
}
</script>
