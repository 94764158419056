<template>
    <div class="col-md-10">
    	<p>Confirm here that the invoice is ok</p>
    	<p>If SDSS etc then generate an order via an api call - to be built</p>
    	<p>email invoice</p>
    </div>
</template>

<script>

import _ from 'lodash'
import BaseForm from './contact/baseform.js'

export default {
    data() {
        return {
        	invoice: {}
        }
    }


}

</script>